import React, { useState } from "react";
import GoogleMapReact from "google-map-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker, faStar } from "@fortawesome/free-solid-svg-icons";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "250px",
    width: "100%",
    borderRadius: "6px",
    overflow: "hidden",
    boxShadow: "1px 1px 5px grey",
    [theme.breakpoints.down("sm")]: {
      marginTop: "25px",
    },
  },
  iconContainer: {
    position: "relative",
    width: "25px",
    height: "33px",
    marginTop: "-33px",
    marginLeft: "-12.5px",
  },
  locationIconRed: {
    position: "absolute",
    color: "red",
  },
  locationIconBlack: {
    position: "absolute",
    color: "black",
  },
  star: {
    position: "absolute",
    marginLeft: "2px",
    marginTop: "3px",
    color: "white",
    padding: 4,
  },
  iconText: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    marginTop: 5,
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
  },
}));

const LocationIcon = ({ index, curr }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconContainer}>
      <FontAwesomeIcon
        className={curr ? classes.locationIconBlack : classes.locationIconRed}
        icon={faMapMarker}
        size="3x"
      />
      {curr ? (
        <FontAwesomeIcon className={classes.star} icon={faStar} size="1x" />
      ) : (
        <p className={classes.iconText}>{index}</p>
      )}
    </div>
  );
};

const MapContainer = ({ relatedPins, thisPin, modal }) => {
  const classes = useStyles();
  const lat = parseFloat(thisPin[0]);
  const lng = parseFloat(thisPin[1]);

  const [defaultProps, setDefaultProps] = useState({
    center: { lat, lng },
    zoom: 9,
  });

  return (
    <div
      className={classes.container}
      style={modal ? { margin: "auto" } : { margin: "20px auto" }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <LocationIcon lat={lat} lng={lng} curr />
        {relatedPins.map((p, i) => {
          const lat = parseFloat(p[0]);
          const lng = parseFloat(p[1]);
          return (
            <LocationIcon
              key={"location" + i}
              lat={lat}
              lng={lng}
              index={i + 1}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default MapContainer;
