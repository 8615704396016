import React, { useState, useEffect, useRef, useMemo } from "react";
import { usePdf } from "react-pdf-js";
import cba from "./CBA.pdf";
import agent_regulations from "./agent_regulations.pdf";
import drug_policy from "./drug_policy.pdf";

const MyPdfViewer = ({ history, match }) => {
  const [page, setPage] = useState(2);
  const [pages, setPages] = useState(null);
  const canvasEl = useRef(null);

  const renderPagination = (page, pages) => {
    if (!pages) {
      return null;
    }
    let previousButton = (
      <li className="previous" onClick={() => setPage(page - 1)}>
        <a href="#">
          <i className="fa fa-arrow-left"></i> Previous
        </a>
      </li>
    );
    if (page === 1) {
      previousButton = (
        <li className="previous disabled">
          <a href="#">
            <i className="fa fa-arrow-left"></i> Previous
          </a>
        </li>
      );
    }
    let nextButton = (
      <li className="next" onClick={() => setPage(page + 1)}>
        <a href="#">
          Next <i className="fa fa-arrow-right"></i>
        </a>
      </li>
    );
    if (page === pages) {
      nextButton = (
        <li className="next disabled">
          <a href="#">
            Next <i className="fa fa-arrow-right"></i>
          </a>
        </li>
      );
    }
    return (
      <nav>
        <ul
          style={{
            display: "flex",
            width: 200,
            justifyContent: "space-around",
            listStyleType: "none",
          }}
        >
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    );
  };

  const file = useMemo(() => {
    if (match.params.document?.toLowerCase() === "cba") {
      return cba;
    }
    if (match.params.document === "agent_regulations") {
      return agent_regulations;
    }
    if (match.params.document === "drug_policy") {
      return drug_policy;
    }
  }, [match.params.document]);

  const [loading, numPages] = usePdf({
    file: file,
    // onDocumentComplete,
    page,
    canvasEl,
    scale: "0.9",
  });

  useEffect(() => {
    setPages(numPages);
  }, [numPages]);

  useEffect(() => {
    setPage(parseInt(match.params.pageNumber));
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 100,
      }}
    >
      {loading && <span>Loading...</span>}
      {renderPagination(page, pages)}
      <canvas ref={canvasEl} />
    </div>
  );
};

export default MyPdfViewer;
