// Actions
const LOAD_ALL = "events/LOAD_ALL";
const LOAD_UNIQUE = "events/LOAD_UNIQUE";
const REMOVE_ALL = "events/REMOVE_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.events.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.event.id]: action.event,
        },
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function loadEvents(events) {
  return { type: LOAD_ALL, events };
}
export function loadEvent(event) {
  return { type: LOAD_UNIQUE, event };
}
export function removeEvents() {
  return { type: REMOVE_ALL };
}
