export const API_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.stratic.app/api/v1/"
    : process.env.REACT_APP_ENV === "development"
    ? "https://samapi.helm.design/api/v1/"
    : "http://localhost:4899/api/v1/";

export const SPOTTRAC_KEY = "0ad9c4dc803d775377e24edd69f1628d";
export const SPOTTRAC_API_URL = "https://www.spotrac.com/api/stratic/";
export const SPOTTRAC_PLAYER = "25102";

export const notificationURLTypes = Object.freeze({
  Global: "dashboard",
  Events: "event",
  Educations: "article",
  Contacts: "leagueaccess",
});

export const notificationTypes = Object.freeze({
  GLOBAL: "Global",
  EVENT: "Events",
  EDUCATION: "Educations",
  PROFESSIONAL_SERVICE: "Professional Services",
  LEAGUE_ACCESS: "League Contacts",
  CONTACT: "Contacts",
  ARCHIVE: "Archived",
  TWITTER: "Twitter",
  ITL: "ITL",
});

export const playerTypes = Object.freeze({
  NONE: "None",
  ROOKIE: "Rookie",
  VETERAN: "Veteran",
  VESTED_VETERAN: "Vested Veteran",
});

export const playerStatusTypes = Object.freeze({
  FREE_AGENT: "Free Agent",
  ACTIVE: "Active",
  INJURED: "Injured Reserve",
  RETIRED: "Retired",
  PUP: "PUP",
  NFI: "NFI",
  PRACTICE_SQUAD: "Practice Squad",
  DRAFT_PROSPECT: "Draft Prospect",
});

export const userTypes = Object.freeze({
  AGENT: "Agent",
  COACH: "Coach",
  EXECUTIVE: "Executive",
  PARENT: "Parent",
  SPOUSE: "Spouse",
  PLAYER: "Professional Player",
  REPORTER: "Reporter",
  COLLEGE: "College Player",
});
