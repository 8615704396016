import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import logo from ".././images/logo.png";

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img src={logo} height={50} alt="logo" />
      </div>
      <div className={classes.container}>
        <h1 className={classes.h1}>Privacy Policy</h1>
      </div>
      <div className={classes.content}>
        <p>
          Stratic Athlete Management LLC (<b>"Company"</b> or <b>"We"</b>)
          respect your privacy and are committed to protecting it through our
          compliance with our Privacy Policy (this “policy”).
        </p>
        <br />
        <p>
          This policy describes the types of information we may collect from you
          or that you may provide when you visit the website www.stratic.app,
          which encompasses any use of our desktop application Stratic or our
          mobile application Stratic (collectively the “<b>Website</b>”), and
          our practices for collecting, using, maintaining, protecting, and
          disclosing that information.
        </p>
        <br />
        <p>
          This policy applies to information we collect on our Website, in
          electronic messages between you and this Website, whether text, email,
          or other medium, and/or via your interactions with our advertising and
          applications on third-party websites, if those applications or
          advertising include links to this policy.
        </p>
        <br />
        <p>
          This policy does not apply to information collected by us offline or
          through any other means, including on any other website operated by
          Company or any third party, or any information collected by any third
          party, including through any application or content (including
          advertising) that may link to or be accessible from or on the Website.
        </p>
        <br />
        <p>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          privacy policy. This policy may change from time to time. Your
          continued use of this Website after we make changes is deemed to be
          acceptance of those changes.
        </p>
        <br />
        <br />
        <b>
          <u>Information We Collect About You and How We Collect It</u>
        </b>
        <br />
        <p>
          We collect several types of information from and about users of our
          Website, including information by which you may be personally
          identified, such as name, postal address, e-mail address, telephone
          number, username, and/or any other identifier by which you may be
          contacted online or offline ("<b>personal information</b>"); as well
          as information your internet connection, the equipment you use to
          access our Website, and usage details.
        </p>
        <br />
        <p>
          We may collect this information directly from you when you provide it
          to us, automatically as you navigate through the site (include usage
          details, IP addresses, and information collected through cookies and
          other tracking technologies), or from third parties.
        </p>
        <br />
        <p>
          The information we collect on or through our Website may include
          information that you provide by filling in forms on our Website. This
          includes information provided at the time of registering to use our
          Website, subscribing to our service, searching, or sharing or posting
          content. We may also ask you for information when you report a problem
          with our Website. This also may include details of transactions you
          carry out through our Website, including but not limited to any
          financial information provided when entering into a transaction
          through our Website.
        </p>
        <br />
        <p>
          You also may provide information to be published or displayed
          (hereinafter, "<b>posted</b>") on public areas of the Website, or
          transmitted to other users of the Website or third parties
          (collectively, "<b>User Contributions</b>"). Your User Contributions
          are posted on and transmitted to others at your own risk. Although you
          may set certain privacy settings for such information by logging into
          your account profile, please be aware that no security measures are
          perfect or impenetrable. Additionally, we cannot control the actions
          of other users of the Website with whom you may choose to share your
          User Contributions. Therefore, we cannot and do not guarantee that
          your User Contributions will not be viewed by unauthorized persons.
        </p>
        <br />
        <i>
          Information We Collect Through Automatic Data Collection Technologies
        </i>
        <br />
        <p>
          As you navigate through and interact with our Website, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions, and patterns, including
          details of your visits to our Website, including traffic data,
          location data, logs, and other communication data and the resources
          that you access and use on the Website, and information about your
          computer and internet connection, including your IP address, operating
          system, and browser type.
        </p>
        <p>
          The information we collect automatically may include personal
          information. It helps us to improve our Website and to deliver a
          better and more personalized service, including by enabling us to
          estimate audience size and usage patterns, store information about
          your preferences, allowing us to customize our Website according to
          your individual interests, speed up searches, and recognize returning
          users.
        </p>
        <br />
        <br />
        <b>
          <u>Third-Party Use of Cookies [and Other Tracking Technologies]</u>
        </b>
        <br />
        <p>
          Some content or applications, including advertisements, on the Website
          are served by third-parties, including advertisers, ad networks and
          servers, content providers, and application providers. These third
          parties may use cookies to collect information about you when you use
          our website. The information they collect may be associated with your
          personal information or they may collect information, including
          personal information, about your online activities over time and
          across different websites and other online services. They may use this
          information to provide you with advertising or other targeted content.
        </p>
        <p>
          We do not control these third parties' tracking technologies or how
          they may be used. If you have any questions about an advertisement or
          other targeted content, you should contact the responsible provider
          directly.
        </p>
        <br />
        <br />
        <b>
          <u>How We Use Your Information</u>
        </b>
        <br />
        <p>
          We use information that we collect about you or that you provide to
          us, including any personal information:
        </p>
        <br />
        <ul>
          <li>To present our Website and its contents to you.</li>
          <li>
            To provide you with information, products, or services that you
            request from us.
          </li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>To provide you with notices about your account.</li>
          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </li>
          <li>
            To notify you about changes to our Website or any products or
            services we offer or provide though it.
          </li>
          <li>
            To allow you to participate in interactive features on our Website.
          </li>
          <li>
            In any other way we may describe when you provide the information.
          </li>
          <li>For any other purpose with your consent.</li>
        </ul>
        <br />
        <p>
          We may also use your information to contact you about goods and
          services that may be of interest to you. If you do not want us to use
          your information in this way, please contact us at
          straticapp@gmail.com.
        </p>
        <br />
        <p>
          We may use the information we have collected from you to enable us to
          display advertisements to our advertisers' target audiences. Even
          though we do not disclose your personal information for these purposes
          without your consent, if you click on or otherwise interact with an
          advertisement, the advertiser may assume that you meet its target
          criteria.
        </p>
        <br />
        <br />
        <b>
          <u>Disclosure of Your Information</u>
        </b>
        <br />
        <p>
          We may disclose aggregated information about our uses from time to
          time. We may disclose personal information that we collect or you
          provide as described in this privacy policy:
        </p>
        <br />
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>
            To contractors, service providers, and other third parties we use to
            support our business[ and who are bound by contractual obligations
            to keep personal information confidential and use it only for the
            purposes for which we disclose it to them.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of Company’s assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which personal information held by Company about our
            Website users is among the assets transferred.
          </li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>
            For any other purpose disclosed by us when you provide the
            information.
          </li>
          <li> With your consent.</li>
        </ul>
        <br />

        <p>We may also disclose your personal information:</p>
        <br />
        <ul>
          <li>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.
          </li>
          <li>
            To enforce or apply our terms of use (found at www.stratic.app) or
            and other agreements, including for billing and collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of Company, our customers, or others.
            This includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk
            reduction.
          </li>
        </ul>
        <br />
        <br />
        <b>
          <u>Choices About How We Use and Disclose Your Information</u>
        </b>
        <br />
        <p>
          We strive to provide you with choices regarding the personal
          information you provide to us. We have created mechanisms to provide
          you with the following control over your information:
        </p>
        <br />
        <ul>
          <li>
            <b>Disclosure of Your Information for Third-Party Advertising.</b>{" "}
            If you do not want us to share your personal information with
            unaffiliated or non-agent third parties for promotional purposes,
            you can opt-out by writing us at straticapp@gmail.com.{" "}
          </li>
          <li>
            <b>[Promotional Offers from the Company.</b> If you do not wish to
            have your [email address/contact information] used by the Company to
            promote our own or third parties' products or services, you can
            opt-out by straticapp@gmail.com.
          </li>
          <li>
            <b>Targeted Advertising.</b> If you do not want us to use
            information that we collect or that you provide to us to deliver
            advertisements according to our advertisers' target-audience
            preferences, you can opt-out by straticapp@gmail.com.
          </li>
        </ul>
        <br />
        <p>
          We do not control third parties' collection or use of your information
          to serve interest-based advertising. However, these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way.
        </p>
        <br />
        <p>
          California residents may have additional personal information rights
          and choices. Please see California Privacy Rights for more
          information.
        </p>
        <br />
        <br />
        <b>
          <u>Accessing and Editing Your Information</u>
        </b>
        <br />
        <p>
          You can review and change your personal information by logging into
          the Website and visiting your account profile page. You may also send
          us an email at straticapp@gmail.com to request access to, correct or
          delete any personal information that you have provided to us. We may
          not accommodate a request to change information if we believe the
          change would violate any law or legal requirement or cause the
          information to be incorrect.
        </p>
        <br />
        <p>
          If you delete your User Contributions from the Website, copies of your
          User Contributions may remain viewable in cached and archived pages,
          or might have been copied or stored by other Website users.
        </p>
        <br />
        <br />
        <b>
          <u>Your California Privacy Rights</u>
        </b>
        <br />
        <p>
          If you are a California resident, California law may provide you with
          additional rights regarding our use of your personal information. To
          learn more about these privacy rights, please visit www.stratic.app
          for more information.
        </p>
        <br />
        <br />
        <b>
          <u>Data Security</u>
        </b>
        <br />
        <p>
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration, and disclosure. [
        </p>
        <br />
        <p>
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Website, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone. We urge you to be careful about giving out information in
          public areas of the Website. The information you share in public areas
          may be viewed by any user of the Website.
        </p>
        <br />
        <p>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Website. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Website.
        </p>
        <br />
        <br />
        <b>
          <u>Changes to Our Privacy Policy</u>
        </b>
        <br />
        <p>
          It is our policy to post any changes we make to our privacy policy on
          this page. If we make material changes to how we treat our users'
          personal information, we will notify you by email to the primary email
          address specified in your account. The date the privacy policy was
          last revised is identified at the top of the page. You are responsible
          for ensuring we have an up-to-date active and deliverable email
          address for you, and for periodically visiting our Website and this
          privacy policy to check for any changes.
        </p>
        <br />
        <br />
        <b>
          <u>Contact Information</u>
        </b>
        <br />
        <p>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us at straticapp@gmail.com.
        </p>
        <br />
        <p>
          To register a complaint or concern, please email us at
          straticapp@gmail.com.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "20px auto 50px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    maxWidth: 650,
    margin: "auto",
  },
  p: {
    fontSize: "16px",
    ...setStyles.sofiaProRegular,
  },
  logoContainer: {
    width: "auto",
  },
}));
