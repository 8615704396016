import React from "react";
import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import logo2 from "../../images/logo2.png";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundImage: "linear-gradient(#FF002B, #832161)",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      height: "450px",
    },
  },
  logoContainer: {
    color: "white",
    width: "auto",
    height: "52px",
    textAlign: "center",
    top: 25,
    left: 25,
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      left: "50%",
      transform: "translate(-50%, -0%)",
    },
  },
  content: {
    height: "300px",
    margin: "auto auto auto 25px",
    maxWidth: "480px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "auto",
    },
  },
  h1: {
    fontSize: "70px",
    color: "#FFFFFF",
    ...setStyles.sofiaProBold,
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "42px",
      margin: "auto auto 25px",
      textAlign: "center",
    },
  },
  h3: {
    color: "#E9CB93",
    fontSize: "30px",
    ...setStyles.sofiaProBold,
    lineHeight: 1,
    marginTop: "26px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      width: "70%",
      margin: "auto",
    },
  },
  container2: {
    color: "#FFFFFF",
    fontSize: "30px",
    ...setStyles.sofiaProBold,
    lineHeight: 1,
    marginTop: "26px",
    display: "flex",
    height: "50px",
    marginTop: "88px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  divider: {
    borderRight: "1px solid white",
    opacity: 0.5,
    margin: "0px 25px",
    height: "100%",
  },
}));

const Hero = () => {
  const classes = useStyles();
  return (
    <Grid item sm={12} md={6} className={classes.container}>
      <div className={classes.logoContainer}>
        <img src={logo2} height={50} alt="logo" />
      </div>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        className={classes.content}
      >
        <h1 className={classes.h1}>Take charge of your career.</h1>
        <h3 className={classes.h3}>
          Harnessing the power of AI to empower NFL players, agents, and their
          families.
        </h3>
      </Grid>
    </Grid>
  );
};

export default Hero;
