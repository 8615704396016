import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 25px",
    },
  },
  h2: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fontSize: "36px",
    paddingBottom: "16px",
  },
  p: {
    color: "#404040",
    opacity: 0.8,
    ...setStyles.sofiaProRegular,
    fontSize: "18px",
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h2 className={classes.h2}>League Contacts</h2>
      <p className={classes.p}>
        Through years of working in the NFL, Stratic's team has compiled a comprehensive contact list consisting of front office executives and scouts to help you navigate through your professional football career. While these contacts are here to assist you, it's important to remember that these executives and scouts get contacted by a lot of people every day, so make sure you don't contact them too often.
      </p>
    </div>
  );
};

export default Header;
