import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 25px",
    },
  },
  h2: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fontSize: "36px",
    paddingBottom: "16px",
  },
  p: {
    color: "#404040",
    opacity: 0.8,
    ...setStyles.sofiaProRegular,
    fontSize: "18px",
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <>
      <h2 className={classes.h2}>Notepad</h2>
      <p className={classes.p}>
        Stratic has built a network of experienced professionals in various
        industries throughout the country to provide players with the best
        possible assistance for their everyday needs. Here you can manage your
        tasks and some notes.
      </p>
    </>
  );
};

export default Header;
