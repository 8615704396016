import React from "react";

import CompareContractsTable from "./CompareContractsTable";
import BoxesContainer from "./BoxesContainer";

const ValueContainer = ({ user }) => {
  return (
    <>
      <BoxesContainer user={user} />
      <CompareContractsTable user={user} />
    </>
  );
};

export default ValueContainer;
