import React, { useMemo, useState, useEffect } from "react";

import TabsNav from "../Components/Navigation/Tabs/TabsNav";
import MobileTabsNav from "../Components/Navigation/Tabs/MobileTabsNav";
import TabView from "../Components/Navigation/Tabs/TabView";
import DetailsContainer from "../Components/Contract/Details/DetailsContainer";
import EarningsContainer from "../Components/Contract/Earnings/EarningsContainer";
import ValueContainer from "../Components/Contract/Value/ValueContainer";

import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchUser } from "../api/user";
import { userTypes } from "../utils/constants";
import { useDispatch } from "react-redux";

const allLabels = ["Contract Details", "Market Value"];

const Contract = ({ width, ...props }) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

  const { match, selectedUser, parent } = props;

  //Will need to make some sort of state for individual user
  const userQuery = useReduxQuery("User", fetchUser);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedUser) {
        setUser(selectedUser);
      } else if (match?.params?.id) {
        //Look through user?.allUsers to find the matching id
        const user = userQuery?.user?.allUsers?.find(
          (user) => user.id == match?.params?.id
        );

        setUser(user);
      } else {
        setUser(userQuery?.user);
      }
    };

    fetchData();
  }, [selectedUser, match, userQuery]);

  const isMarketValue = useMemo(() => {
    return user?.marketValue !== null &&
      !parent &&
      (user?.marketValue?.averageAnnualSalary !== null ||
        user?.marketValue?.nflRank !== null ||
        user?.marketValue?.positionRank !== null ||
        user?.marketValue?.fiveYearProjection !== null ||
        user?.marketValue?.comparablePlayers.length)
      ? true
      : false;
  }, [user?.marketValue]);

  const labels = useMemo(
    () => (isMarketValue ? allLabels : allLabels.slice(0, 1)),
    [isMarketValue]
  );

  const selectTab = (index) => setTabIndex(index);

  const displayTabView = () =>
    tabIndex === 0 ? (
      <DetailsContainer
        user={user}
        parent={
          user?.userType === userTypes.PARENT ||
          user?.userType === userTypes.SPOUSE
        }
        page={true}
      />
    ) : (
      <ValueContainer user={user} />
    );

  return (
    <div className={classes.container}>
      {user?.userType === userTypes.PLAYER &&
        (isWidthDown("sm", width) ? (
          <MobileTabsNav
            labels={labels}
            index={tabIndex}
            selectTab={selectTab}
          />
        ) : (
          <TabsNav labels={labels} index={tabIndex} selectTab={selectTab} />
        ))}
      <TabView>{displayTabView()}</TabView>
    </div>
  );
};

export default withWidth()(Contract);

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "auto",
  },
}));
