import React, { useEffect, useState } from "react";

import ArticleContent from "../Components/Articles/ArticleContent";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { fetchArticle } from "../api/articles";
import { useReduxQuery } from "../hooks/useReduxQuery";
import RelatedArticle from "../Components/Articles/RelatedArticles";

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto auto",
  },
}));

const Article = ({ match }) => {
  const id = match.params.id;

  const classes = useStyles();
  const { article } = useReduxQuery("Article", () => fetchArticle(id), {
    selector: (state) => state.articles.data[id],
  });

  return (
    <div className={classes.container}>
      <ArticleContent article={article} />
      <RelatedArticle articles={article?.relatedArticles || []} />
    </div>
  );
};

export default Article;
