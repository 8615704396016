// Actions
const LOAD_ALL = "contacts/LOAD_ALL";
const LOAD_UNIQUE = "contacts/LOAD_UNIQUE";
const REMOVE_ALL = "contacts/REMOVE_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.contacts.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
      return {
        ...state,
        data: { ...state.data, [action.contact.id]: action.contact },
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function loadContacts(contacts) {
  return { type: LOAD_ALL, contacts };
}
export function loadContact(contact) {
  return { type: LOAD_UNIQUE, contact };
}
export function removeContacts() {
  return { type: REMOVE_ALL };
}
