import React from "react";

import { Grid } from "@material-ui/core";
import Carousel from "../Standalones/Carousel";

import ArticleCard from "../Standalones/ArticleCard";
import Arrow from "../../images/Icons/Arrow";

import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  h3: {
    fontSize: "24px",
    ...setStyles.sofiaProSemiBold,
    color: "#05070b",
    marginBottom: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
  aContainer: {
    margin: "67px auto 75px",
    textAlign: "center",
  },
  a: {
    color: "#FF002B",
    fontSize: "20px",
    ...setStyles.sofiaProSemiBold,
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
  },
  arrow: {
    transform: "scaleX(-1)",
    paddingLeft: "10px",
  },
  articleDiv: {
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

const RelatedArticles = ({ width, articles }) => {
  const classes = useStyles();

  const displayCards = () => {
    const comps = articles.map((art, i) => (
      <div className={classes.articleDiv} key={i}>
        <ArticleCard
          subject={art.tags?.join(", ")}
          heading={art.title}
          content={art.description}
          id={art.id}
        />
      </div>
    ));

    if (isWidthUp("md", width) && comps.length <= 3) {
      return (
        <Grid
          container
          justifyContent="flex-start"
          className={classes.cardsContainer}
        >
          {comps}
        </Grid>
      );
    } else {
      return <Carousel>{comps}</Carousel>;
    }
  };

  if (articles.length > 0) {
    return (
      <div className={classes.container}>
        <h3 className={classes.h3}>Related Articles</h3>
        {displayCards()}
        {/* <div className={classes.aContainer}>
          <a className={classes.a} href="/playerEducation">
            <div className={classes.arrow}>
              <Arrow color="red" />
            </div>{" "}
            Back to articles
          </a>
        </div> */}
      </div>
    );
  } else return null;
};

export default withWidth()(RelatedArticles);
