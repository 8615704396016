import { loadEvent, loadEvents } from "../redux/events";
import { axiosHandler } from "../lib/utils";

export const fetchEvents = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({ url: `events`, state: getState() });
    await dispatch(loadEvents(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchEvent = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({ url: `events/${id}`, state: getState() });
    await dispatch(loadEvent(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};
