import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchArticles } from "../api/articles";
import ArticlesContainer from "../Components/Dashboard/ArticlesContainer";

const ProfessionalDevelopment = () => {
  const classes = useStyles();
  const { articles } = useReduxQuery("Articles", fetchArticles, {
    selector: (state) =>
      Object.values(state.articles.data || []).filter((c) =>
        c?.tags?.some((t) => t?.toLowerCase() === "professionaldevelopment")
      ),
  });

  return (
    <div className={classes.container}>
      <ArticlesContainer articles={articles} title="Professional Development" />
    </div>
  );
};

export default ProfessionalDevelopment;

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: "150px",
  },
  h4: {
    padding: "25px",
  },
  button: {
    width: 200,
    height: 35,
    borderRadius: 11,
    border: "none",
    backgroundColor: "#ff9100",
    color: "white",
    fontSize: 16,
  },
}));
