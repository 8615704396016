import React, { useState, useEffect } from "react";
import DetailsContainer from "../Components/Contract/Details/DetailsContainer";
import withWidth from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { fetchPlayerData } from "../api/spotrac";
import { playerTypes } from "../utils/constants";

const SelectedContract = ({ match, selectedUser, parent }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);

  const getSelectedPlayer = async () => {
    const { id } = match.params;
    setLoading(true);
    const player = await fetchPlayerData(id);
    setSelected({
      spotracId: player?.details?.ids?.id,
      name: `${player?.details?.name?.full_name}`,
      contract: {
        years: `${player?.details?.experience?.years}`,
      },
      nextPaycheck: null,
      currentAccrued: 0,
      totalAccrued: 1,
      currentCredited: 0,
      totalCredited: 1,
      breakdowns: player?.contracts?.[0]?.breakdown?.map((b) => ({
        year: b.year,
        team: { name: b.team_name },
        age: 0,
        baseSalary: b.cash_base,
        capHit: b.cap_signing,
        deadCap: b.cap_cap,
        signingBonus: b.cash_signing,
        yearlyCash: b.cash_total,
      })),
      description: null,
      totalSalary: player?.contracts?.[0]?.details?.value,
      years: player?.contracts?.[0]?.details?.years,
      status: "N/A",
      type:
        player?.contracts?.[0]?.details?.years === 1
          ? playerTypes.ROOKIE
          : player?.contracts?.[0]?.details?.years > 1 &&
            player?.contracts?.[0]?.details?.years < 4
          ? playerTypes.VETERAN
          : player?.contracts?.[0]?.details?.years >= 4
          ? playerTypes.VESTED_VETERAN
          : playerTypes.NONE,
      startYear: player?.contracts?.[0]?.details?.start,
      endYear: player?.contracts?.[0]?.details?.end,
    });
    setLoading(false);
  };

  useEffect(() => {
    !selectedUser && match.params.id && getSelectedPlayer();
  }, [match.params.id, selectedUser]);

  useEffect(() => {
    selectedUser && setLoading(false);
  }, [selectedUser]);

  if (loading) return <div className={classes?.container}>Loading...</div>;

  if (!selected && !selectedUser)
    return <div className={classes?.container}>No contract found</div>;

  return (
    <div className={classes.container}>
      {selectedUser ? (
        <h1>{selectedUser.firstName + " " + selectedUser.lastName}</h1>
      ) : (
        <h1>{selected.name}</h1>
      )}
      {selectedUser ? (
        <DetailsContainer user={selectedUser} parent={parent} />
      ) : (
        <DetailsContainer selected={selected} />
      )}
    </div>
  );
};

export default withWidth()(SelectedContract);

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "auto",
  },
}));
