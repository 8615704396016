import React from "react";

import { Box, Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const Filter = ({
  leagueFilter,
  leagueFilters,
  serviceFilter,
  serviceFilters,
  onSelectLeague,
  onSelectService,
  search,
  onSearch,
  nbContacts,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <p className={classes.p}>{`${nbContacts} Contact${
        nbContacts > 1 ? "s" : ""
      } Found`}</p>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={classes.sortContainer}
      >
        <Box
          className={classes.innerSortCont1}
          style={{
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={12} md={"auto"}>
            <h5>Filter By League:</h5>
          </Grid>
          {leagueFilters.map((lf, i) => (
            <button
              key={i}
              name={0}
              className={
                leagueFilter === lf ? classes.sortButtonA : classes.sortButton
              }
              onClick={() => onSelectLeague(lf)}
            >
              {lf?.toUpperCase()}
            </button>
          ))}
        </Box>
        <Box
          justifyContent="space-between"
          alignItems="center"
          className={classes.innerSortCont1}
          style={{
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={12} md={"auto"}>
            <h5>Filter By Services:</h5>
          </Grid>
          {serviceFilters.map((sf, i) => (
            <button
              key={i}
              name={0}
              className={
                serviceFilter === sf ? classes.sortButtonA : classes.sortButton
              }
              onClick={() => onSelectService(sf)}
            >
              {sf?.toUpperCase()}
            </button>
          ))}
        </Box>
        <Grid container alignItems="center" className={classes.innerSortCont2}>
          <input
            placeholder={`Search...`}
            value={search}
            className={classes.searchInput}
            onChange={(e) => onSearch(e?.target?.value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Filter;

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "auto",
    padding: "45px 0px 15px",
    borderBottom: "2px solid rgba(155, 156, 157, .2)",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  p: {
    color: "#05070B",
    fontSize: "24px",
    ...setStyles.sofiaProRegular,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  sortContainer: {
    padding: "21px 0px 15px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "12px 0px",
    },
  },
  h5: {
    ...setStyles.sofiaProBold,
    fontSize: "18px",
  },
  innerSortCont1: {
    width: "100%",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "auto",
    },
  },
  innerSortCont2: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto auto",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "15px auto auto",
    },
  },
  searchInput: {
    height: "35px",
    width: "300px",
    borderRadius: "20px",
    boxShadow: "0px 0px 10px #0000003E",
    border: "none",
    padding: "5px 15px",
    ...setStyles.sofiaProBold,
    fontSize: "18px",
    color: "#05070B",
    margin: "10px 0px",
  },
  sortButtonA: {
    margin: "10px",
    padding: "2px 10px",
    border: "none",
    backgroundColor: "#A28C62",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  sortButton: {
    margin: "10px",
    padding: "2px 10px",
    border: "none",
    backgroundColor: "rgb(233,203,147, 0.2)",
    borderRadius: "4px",
    color: "#A28C62",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  alphaContainer: {
    backgroundColor: "rgb(233,203,147, 0.2)",
    borderRadius: "8px",
    height: "56px",
    padding: "0px 20px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  letterButton: {
    color: "#A28C62",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    background: "none",
    padding: "14px 11px",
    border: "none",
  },
}));
