import React from "react";
import TeamContainer from "./TeamContainer";
import { makeStyles } from "@material-ui/core/styles";

const ContactsContainer = ({ contacts }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {Object.values(contacts || []).map(({ title, data }, i) => (
        <TeamContainer team={title} contacts={data} key={i} />
      ))}
    </div>
  );
};

export default ContactsContainer;

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: "hidden",
    padding: "0px 10px",
    maxHeight: "calc(100vh - 200px)",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100vh - 270px)",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "calc(100vh - 240px)",
    },
  },
}));
