import React from "react";

import Details from "../Components/Event/Details";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchEvent } from "../api/events";
import RelatedArticles from "../Components/Articles/RelatedArticles";
import RelatedEvents from "../Components/Event/RelatedEvents";
import { CircularProgress } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  eventContainer: {
    width: "100%",
    height: "100%",
    maxWidth: setStyles.maxWidth,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    marginBottom: "100px",
  },
}));

const Event = ({ match }) => {
  const classes = useStyles();

  const id = match.params.id;
  const { event, loadingEvent } = useReduxQuery("Event", () => fetchEvent(id), {
    selector: (state) => state.events.data[id],
  });

  if (loadingEvent && !event) {
    return (
      <div className={classes.eventContainer}>
        <CircularProgress />
      </div>
    );
  }
  if (!loadingEvent && !event) return "No Event";

  return (
    <div className={classes.eventContainer}>
      <Details
        content={event.description}
        contacts={event.contacts}
        event={event}
      />
      <RelatedArticles articles={event.articles} />
      <RelatedEvents event={event} events={event.subEvents} />
    </div>
  );
};

export default Event;
