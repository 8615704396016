import React from "react";

import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    width: "100%",
    borderTop: "grey 1px solid",
    paddingTop: 50,
    marginTop: 50,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  h2: {
    ...setStyles.sofiaProBold,
    fontSize: "28px",
    paddingBottom: "20px",
    width: "100%",
  },
  subheading: {
    color: "#404040",
    opacity: 0.75,
    fontSize: "20px",
    letterSpacing: "0.18px",
    ...setStyles.sofiaProLight,
    paddingBottom: "30px",
    lineHeight: "30px",
    width: "80%",
  },
  list: {
    listStyleType: "none",
  },
  listItem: {
    fontSize: 18,
    fontWeight: 300,
    paddingTop: 5,
  },
  listTag: {
    textDecoration: "none",
  },
  h3: {
    fontSize: 22,
    marginTop: 20,
  },
  iconBackground: {
    borderRadius: "50%",
    width: 100,
    height: 100,
    padding: 10,
    margin: "0px auto auto auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const PopularArticles = ({
  title,
  subheading,
  articles,
  seeAll,
  icon,
  color,
  backgroundColor,
  type,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container} id={title.replace(/\s/g, "")}>
      <Grid container>
        <Grid item xs={10}>
          <h2 className={classes.h2} style={{ color }}>
            {title}
          </h2>
          <p className={classes.subheading}>{subheading}</p>
          <div>
            <p
              style={{
                color: "#404040",
              }}
            >
              Popular Articles About This Topic:
            </p>
            <ul className={classes.list}>
              {(articles || []).map((a, i) => (
                <li className={classes.listItem} key={i}>
                  <a
                    className={classes.listTag}
                    style={{
                      color,
                      fontWeight: 500,
                      fontSize: 20,
                    }}
                    href={`/article/${a.id}`}
                  >
                    {a.title}
                  </a>
                </li>
              ))}
            </ul>
            <h3 className={classes.h3}>
              <a
                className={classes.listTag}
                style={{
                  color,
                  backgroundColor,
                  borderRadius: 25,
                  padding: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  textTransform: "uppercase",
                  fontSize: 14,
                }}
                href={`/articles/${type}`}
              >
                {seeAll.title}
              </a>
            </h3>
          </div>
        </Grid>
        <Grid item xs={2} alignItems="center" className={classes.icon}>
          <div className={classes.iconBackground} style={{ backgroundColor }}>
            {icon}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PopularArticles;
