import { loadArticles, loadArticle } from "../redux/articles";
import { axiosHandler } from "../lib/utils";

export const fetchArticles = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({ url: `articles`, state: getState() });
    await dispatch(loadArticles(json.data));
  } catch (error) {
    throw error;
  }
};

export const fetchArticle = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `articles/${id}`,
      state: getState(),
    });
    await dispatch(loadArticle(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};
