import React, { memo, useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import Arrow from "../../images/Icons/Arrow";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { HyperlinkURLs, extractVideoId } from "../../utils/utils";
import YouTube from "react-youtube";
import { documentTypes, documentLinkTypes } from "../../lib/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    maxWidth: "800px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  breadcrumbs: {
    paddingBottom: "10px",
  },
  span1: {
    color: "#9B9C9D",
    ...setStyles.sofiaProBold,
    fontSize: "16px",
  },
  span2: {
    color: "#A28C62",
    ...setStyles.sofiaProBold,
    fontSize: "16px",
  },
  h1: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fnotSize: "36px",
    paddingBottom: "20px",
    width: "100%",
  },
  subheading: {
    color: "#404040",
    opacity: 0.75,
    fontSize: "30px",
    letterSpacing: "0.18px",
    ...setStyles.sofiaProLight,
    paddingBottom: "30px",
    lineHeight: "30px",
    width: "100%",
  },
  p: {
    ...setStyles.sofiaProRegular,
    color: "#05070B",
    opacity: 0.75,
    fontSize: "16px",
    letterSpacing: "0.1px",
    paddingBottom: "15px",
    width: "100%",
  },
  updated: {
    color: "#C0C1C2",
    fontSize: "12px",
    ...setStyles.sofiaProBold,
    paddingBottom: "30px",
  },
  a: {},
  CBAConnectContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    maxWidth: "100%",
    height: "50px",
    backgroundColor: "#D8E2F9",
    borderRadius: "46px",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    marginBottom: "75px",
    cursor: "pointer",
    textDecoration: "none",
  },
  CBAConnect: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    maxWidth: "100%",
    height: "50px",
    color: "#3F6EDE",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
  },
  arrow: {
    paddingLeft: "5px",
  },
  fileContainer: {
    height: "auto",
    maxWidth: "800px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "auto",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  fileName: {
    flex: 1,
    marginRight: "10px",
    color: "#333",
    fontSize: "16px",
  },

  downloadButton: {
    backgroundColor: "#000080",
    color: "white",
    border: "none",
    padding: "8px 10px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#008000",
    },
  },
}));

const handleViewOrDownload = (key) => {
  if (key.includes("pdf")) {
    window.open(key, "_blank");
  } else if (key.includes("docx") || key.includes("doc")) {
    window.open(key, "_blank");
  } else {
    alert("Unsupported file format. Please download the file.");
  }
};

const MemoizedHyperlinkURLs = memo(HyperlinkURLs);

const ArticleContent = ({ article }) => {
  const classes = useStyles();
  const files = article?.fileurls ? article?.fileurls : [];
  const contractNames = article?.contractKey
    ? article?.contractKey?.split(",").map((item) => {
        const parts = item.split("~");
        const fileName = parts[0];
        const extension = parts[1].slice(parts[1].lastIndexOf("."));
        return { name: fileName + extension };
      })
    : [];

  const videoId = extractVideoId(article?.videoLink);

  const [dimensions, setDimensions] = useState({
    width: "100%", // Default to full width
    height: "390", // Default height
  });

  useEffect(() => {
    const handleResize = () => {
      // Set the width to the maxWidth or window's width
      const containerWidth = Math.min(
        window.innerWidth,
        setStyles.maxWidth.replace("px", "")
      );
      console.log("containerWidth", window.innerWidth);
      console.log("containerWidth", containerWidth);
      // Calculate height with 16:9 aspect ratio
      const calculatedHeight = (containerWidth * 9) / 16;
      setDimensions({
        width: containerWidth - 80,
        height: calculatedHeight - 80,
      });
    };

    // Call once to set initial values
    handleResize();

    // Add event listener to resize dynamically
    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={classes.container}>
      <Grid container direction="column">
        <div className={classes.breadcrumbs}>
          <span className={classes.span2}>
            {article.tags
              ?.map((tag) => {
                if (["contractrepository"].includes(tag.toLowerCase())) {
                  return tag.replace(
                    /contractrepository/gi,
                    "Contract Repository"
                  );
                } else if (["strategicpartners"].includes(tag.toLowerCase())) {
                  return tag.replace(
                    /strategicpartners/gi,
                    "Strategic Partners"
                  );
                } else if (
                  ["professionaldevelopment"].includes(tag.toLowerCase())
                ) {
                  return tag.replace(
                    /professionaldevelopment/gi,
                    "Professional Development"
                  );
                } else {
                  return tag;
                }
              })
              .join(", ")}
          </span>
        </div>
        <h1 className={classes.h1}>{article.title}</h1>
        <MemoizedHyperlinkURLs article={article} classes={classes} />
        {!!videoId && (
          <YouTube
            videoId={videoId}
            opts={{
              height: dimensions.height.toString(),
              width: dimensions.width.toString(),
            }}
          />
        )}
        <div style={{ height: 20 }} />
        {article?.cbaAnchor && (
          <button className={classes.CBAConnectContainer}>
            <a
              href={`/documents/${documentLinkTypes[article.anchorDocument]}/${
                article.cbaAnchor
              }`}
              className={classes.CBAConnect}
            >
              <DescriptionOutlinedIcon
                fontSize="large"
                style={{ paddingRight: "5px" }}
              />
              {`Review section of ${documentTypes[article.anchorDocument]}`}
              <div className={classes.arrow}>
                <Arrow color="blue" />
              </div>
            </a>
          </button>
        )}
        {files && contractNames && files.length === contractNames.length && (
          <ul className={classes.fileContainer}>
            {files.map((key, index) => (
              <li key={index} className={classes.listItem}>
                <div className={classes.fileItem}>
                  <span className={classes.fileName}>
                    {contractNames[index].name}
                  </span>
                  <button
                    className={classes.downloadButton}
                    onClick={() => handleViewOrDownload(key)}
                  >
                    View / Download
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Grid>
    </div>
  );
};

export default ArticleContent;
