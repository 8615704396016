import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";

const useStyles = makeStyles((theme) => ({
  iconLinkContainer: {
    textAlign: "center",
  },
  iconLinkA: {
    textDecoration: "none",
  },
  iconLinkP: {
    fontSize: "0.75rem",
    ...setStyles.sofiaProBold,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  iconLinkPActive: {
    fontSize: "0.75rem",
    ...setStyles.sofiaProBold,
    color: "#FF002B",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  img: {
    width: "20px",
    height: "20px",
    fill: "red",
    [theme.breakpoints.down("xs")]: {
      margin: "auto 15px",
    },
  },
}));

const IconLink = ({ text, image, children, url, active }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconLinkContainer}>
      <a href={url} className={classes.iconLinkA}>
        {children}
        <p className={active ? classes.iconLinkPActive : classes.iconLinkP}>
          {text}
        </p>
      </a>
    </div>
  );
};

export default IconLink;
