import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { getAgentNotes } from "../api/user";
import ExpandableSection from "../Components/Standalones/ExpandableSection";
import { createAgentNote, updateAgentNote, deleteAgentNote } from "../api/user";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../Components/Notepad/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faCheck,
  faTrash,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import setStyles from "../setStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto 50px",
  },
  input: {
    marginTop: theme.spacing(2),
    height: 80,
    width: "70%",
    borderColor: theme.palette.text.secondary,
    borderWidth: 1,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    textAlign: "center",
  },
  card: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.text.secondary,
    borderWidth: 1,
    marginBottom: theme.spacing(2),
  },
  noteText: {
    marginLeft: theme.spacing(1),
  },
}));

const Notepad = () => {
  const [newNote, setNewNote] = useState("");
  const [editingNote, setEditingNote] = useState(null);
  const [editNoteContent, setEditNoteContent] = useState("");
  const dispatch = useDispatch();

  const { agent } = useReduxQuery("Agent", getAgentNotes);

  const handleAddNote = async () => {
    if (newNote.trim() !== "") {
      await dispatch(createAgentNote({ note: newNote }));
      setNewNote("");
    }
  };

  const handleEditNote = async (noteId) => {
    await dispatch(updateAgentNote({ id: noteId, note: editNoteContent }));
    setEditingNote(null);
  };

  const handleDeleteNote = async (noteId) => {
    await dispatch(deleteAgentNote(noteId));
  };

  const handleToggleComplete = async (noteId, isComplete) => {
    await dispatch(
      updateAgentNote({
        id: noteId,
        completed: !isComplete,
      })
    );
  };

  const handleKeyPress = (event, noteId) => {
    if (event.nativeEvent.key === "Enter" && !event.nativeEvent.shiftKey) {
      event.preventDefault();
      noteId ? handleEditNote(noteId) : handleAddNote();
    }
  };

  const uncompletedNotes = useMemo(
    () =>
      Object.values(agent?.notes || [])
        .filter((note) => !note.completed)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
    [agent]
  );

  const completedNotes = useMemo(
    () =>
      Object.values(agent?.notes || [])
        .filter((note) => note.completed)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
    [agent]
  );

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Header />
      <div
        className="container2"
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <textarea
          className={classes.input}
          onChange={(e) => setNewNote(e.target.value)}
          value={newNote}
          placeholder="Add a new note..."
          onKeyDown={(event) => handleKeyPress(event)}
        />
      </div>
      <ExpandableSection placeholder="Uncompleted Tasks">
        {uncompletedNotes.map((note) => (
          <NoteCard
            key={note.id}
            note={note}
            editingNote={editingNote}
            editNoteContent={editNoteContent}
            setEditingNote={setEditingNote}
            setEditNoteContent={setEditNoteContent}
            handleKeyPress={handleKeyPress}
            handleEditNote={handleEditNote}
            handleToggleComplete={handleToggleComplete}
            handleDeleteNote={handleDeleteNote}
            classes={classes}
          />
        ))}
      </ExpandableSection>
      <ExpandableSection placeholder="Completed Tasks">
        {completedNotes.map((note) => (
          <NoteCard
            key={note.id}
            note={note}
            editingNote={editingNote}
            editNoteContent={editNoteContent}
            setEditingNote={setEditingNote}
            setEditNoteContent={setEditNoteContent}
            handleKeyPress={handleKeyPress}
            handleEditNote={handleEditNote}
            handleToggleComplete={handleToggleComplete}
            handleDeleteNote={handleDeleteNote}
            classes={classes}
          />
        ))}
      </ExpandableSection>
    </div>
  );
};

const NoteCard = ({
  note,
  editingNote,
  editNoteContent,
  setEditingNote,
  setEditNoteContent,
  handleKeyPress,
  handleEditNote,
  handleToggleComplete,
  handleDeleteNote,
  classes,
}) => {
  return (
    <div className={classes.card}>
      {editingNote === note.id ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <textarea
            className={classes.input}
            multiline={true}
            value={editNoteContent}
            onChange={(e) => setEditNoteContent(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, note.id)}
          />
          <div>
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
              onClick={() => setEditingNote(null)}
            />
          </div>
        </div>
      ) : (
        <>
          <p className={classes.noteText}>{note.note}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "auto",
            }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              size="lg"
              onClick={() => {
                setEditingNote(note.id);
                setEditNoteContent(note.note);
              }}
            />
            <FontAwesomeIcon
              icon={faTrash}
              size="lg"
              onClick={() => handleDeleteNote(note.id)}
            />
            <FontAwesomeIcon
              icon={faCheck}
              size="lg"
              onClick={() => handleToggleComplete(note.id, note.completed)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Notepad;
