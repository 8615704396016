import React from "react";

import FeatureCard from "../Standalones/FeatureCard";
import FeatureCardSmall from "../Standalones/FeatureCardSmall";
import EventCard from "../Standalones/EventCard";

import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";

const EventsCards = ({ events, width }) => {
  const classes = useStyles();

  let firstFeatureCard = false;

  return (
    <div className={classes.container}>
      {(events || []).map((event) => {
        const location = event.city || event.state ? `${event.city || ""}${event.city && event.state ? ", " : ""}${event.state || ""}` : "";
        if (event.featured) {
          if (!firstFeatureCard && isWidthUp("sm", width)) {
            firstFeatureCard = true;
            return (
              <div className={classes.feature1} key={event.title}>
                <FeatureCard
                  heading={event.title}
                  location={location}
                  content={event.description?.substring(0, 70)}
                  date={event.startDate}
                  categories={event.categories}
                  id={event.id}
                  coverUrl={event.coverUrl}
                />
              </div>
            );
          } else {
            return (
              <div className={classes.feature2} key={event.title}>
                <FeatureCardSmall
                  heading={event.title}
                  location={location}
                  content={event.description?.substring(0, 70)}
                  date={event.startDate}
                  categories={event.categories}
                  id={event.id}
                  coverUrl={event.coverUrl}
                />
              </div>
            );
          }
        } else {
          return (
            <EventCard
              heading={event.title}
              location={location}
              date={event.startDate}
              categories={event.categories}
              id={event.id}
              key={event.title}
              coverUrl={event.coverUrl}
            />
          );
        }
      })}
    </div>
  );
};

export default withWidth()(EventsCards);

const useStyles = makeStyles(() => ({
  container: {
    display: "grid",
    gridGap: "30px",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 300px))",
    gridAutoRows: "235px",
    margin: "50px auto",
    justifyContent: "center",
  },
  feature1: {
    gridColumnEnd: "span 2",
    gridRowEnd: "span 2",
    height: "100%",
    width: "100%",
  },
  feature2: {
    gridColumnEnd: "span 1",
    gridRowEnd: "span 2",
    height: "100%",
    width: "100%",
  },
  event: {
    gridColumn: "span 1",
    gridRow: "span 1",
    backgroundColor: "blue",
    height: "100%",
    width: "100%",
  },
}));
