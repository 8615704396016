// Actions
const LOAD_UNIQUE = "user/LOAD_UNIQUE";
const LOAD_SPOTRAC = "user/LOAD_SPOTRAC";
const UPDATE = "user/UPDATE";
const CLEAR_CACHE = "user/CLEAR_CACHE";
const LOAD_ALL = "user/LOAD_ALL";

// Reducer
const initialState = {
  data: null,
  spotrac: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_UNIQUE:
      return { ...state, data: action.user };
    case LOAD_SPOTRAC:
      return { ...state, spotrac: action.player };
    case LOAD_ALL:
      return { ...state, data: { ...state.data, allUsers: action.users } };
    case UPDATE:
      return {
        ...state,
        data: { ...state.data, ...action.user },
      };
    case CLEAR_CACHE:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function loadUser(user) {
  return { type: LOAD_UNIQUE, user };
}
export function loadSpotrac(player) {
  return { type: LOAD_SPOTRAC, player };
}
export function putUser(user) {
  return { type: UPDATE, user };
}
export function removeUser() {
  return { type: CLEAR_CACHE };
}
export function loadAllUsers(users) {
  return { type: LOAD_ALL, users };
}
