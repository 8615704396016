import axios from "axios";
import { API_URL } from "./constants";

export const axiosHandler = async ({
  url,
  params,
  method = "GET",
  data,
  state,
  headers,
  customBaseURL,
}) => {
  if (!url) throw Error("NO_URL_SPECIFIED");
  if (!method) throw Error("NO_METHOD_SPECIFIED");
  const baseURL = customBaseURL || API_URL;
  const options = {
    url,
    baseURL,
    method,
    params,
    data,
    headers,
    crossDomain: true,
  };
  if (state) {
    const { accessToken } = state.auth;
    if (!accessToken) throw Error("TOKEN_REQUIRED");
    options["headers"] = {
      ...options["headers"],
      Authorization: `Bearer ${accessToken}`,
    };
  }
  try {
    const res = await axios(options);
    return res.data;
  } catch (error) {
    throw Error(error?.response?.data?.error || error);
  }
};

export const appendParamsToUrl = (params) =>
  Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

export const lowerFirstLetter = (string) => {
  return string?.charAt(0)?.toLowerCase() + string?.slice(1);
};

export const upperFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const numberFormatter = (num) => {
  if (!num) return "1";
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
};

export const wait = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const truncate = (str, n = 100) => {
  return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const reduceContacts = (filteredContacts) => {
  const grouped = Object.entries(
    Object.values(filteredContacts || []).reduce((acc, contact) => {
      const key =
        contact?.team?.city && contact?.team?.name
          ? `${contact.team.city} ${contact.team.name}`
          : "Others";
      acc[key] = [...(acc[key] || []), contact];
      return acc;
    }, {})
  ).map(([key, value]) => ({
    title: key,
    data: value,
  }));

  grouped.sort((a, b) => {
    if (a.title === "Others") return 1;
    if (b.title === "Others") return -1;
    return a.title.localeCompare(b.title);
  });

  return grouped;
};
export const reduceMarketingContacts = (filteredContacts) => {
  const grouped = Object.entries(
    Object.values(filteredContacts || []).reduce((acc, contact) => {
      const keys = contact?.services || ["Others"];
      keys.forEach((key) => {
        acc[key] = [...(acc[key] || []), contact];
      });
      return acc;
    }, {})
  ).map(([key, value]) => ({
    title: key,
    data: value,
  }));

  grouped.sort((a, b) => {
    if (a.title === "Others") return 1;
    if (b.title === "Others") return -1;
    return a.title.localeCompare(b.title);
  });

  return grouped;
};
