import React from "react";
import { Route, Router } from "react-router-dom";
import { ModalProvider } from "./Context/ModalContext";

import Login from "./screens/Login";
import HeaderContainer from "./Components/Header/HeaderContainer";
import Dashboard from "./screens/Dashboard";
import Contract from "./screens/Contract";
import Events from "./screens/Events";
import Event from "./screens/Event";
import Article from "./screens/Article";
import LeagueAccess from "./screens/LeagueAccess";
import Contacts from "./screens/Contacts";
import ProfessionalDevelopment from "./screens/ProfessionalDevelopment";
import ArticlesMain from "./screens/ArticlesMain";
import Blank from "./Components/Blank";
import CBApdf from "./screens/CBApdf";
import PlayerEducation from "./screens/PlayerEducation";
import SelectedContract from "./screens/SelectedContract";
import Parent from "./screens/Parent";
import Spouse from "./screens/Spouse";
import Notepad from "./screens/Notepad";

import Modal from "./Components/Standalones/Modal";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { withSnackbar } from "notistack";
import useNotifications from "./hooks/useNotifications";
import history from "./history";
import Notifications from "./screens/Notifications";
import ResetPassword from "./Components/Login/ResetPassword";
import PrivacyPolicy from "./screens/PrivacyPolicy";

import { userTypes } from "./utils/constants";

import { useReduxQuery } from "./hooks/useReduxQuery";
import { fetchUser } from "./api/user";
import { contactTypes } from "./lib/constants";

const Navigation = ({ enqueueSnackbar }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  useNotifications({ enqueueSnackbar });
  const { user } = useReduxQuery("User", fetchUser);

  return (
    <Router history={history}>
      <Route exact path="/privacy-policy">
        <PrivacyPolicy history={history} />
      </Route>
      <Route exact path="/login">
        {accessToken ? (
          <>
            <HeaderContainer history={history} />
            <Dashboard history={history} />
          </>
        ) : (
          <Login history={history} />
        )}
      </Route>
      <Route exact path="/reset-password">
        {accessToken ? (
          <>
            <HeaderContainer history={history} />
            <Dashboard history={history} />
          </>
        ) : (
          <ResetPassword history={history} />
        )}
      </Route>
      <Route exact path="/">
        {!accessToken ? (
          <Redirect to="/login" />
        ) : (
          <>
            <HeaderContainer history={history} />
            <Dashboard history={history} />
          </>
        )}
      </Route>
      <Route
        exact
        path="/documents/:document/:pageNumber/"
        render={(props) => (
          <div>
            <HeaderContainer removeWelcome minimal history={props.history} />
            <CBApdf history={props.history} match={props.match} />
          </div>
        )}
      />
      <Route
        exact
        path="/documents/:document/mobile/:pageNumber/"
        render={(props) => (
          <CBApdf history={props.history} match={props.match} />
        )}
      />

      <Route
        exact
        path="/Contract"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : user?.userType === userTypes.PARENT ||
            user?.userType === userTypes.SPOUSE ? (
            <Redirect to="/" />
          ) : (
            <>
              <HeaderContainer history={props.history} />
              <Contract history={props.history} />
            </>
          )
        }
      />
      <Route
        exact
        path="/Contract/:id"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <>
              <HeaderContainer history={props.history} />
              <SelectedContract
                history={props.history}
                match={props.match}
                selectedUser={props?.location?.selectedUser}
                parent={props?.location?.parent}
              />
            </>
          )
        }
      />
      <Route
        exact
        path="/Events"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <>
              <HeaderContainer removeWelcome history={props.history} />
              <Events history={props.history} />
            </>
          )
        }
      />
      <Route
        exact
        path="/Event/:id"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <>
              <HeaderContainer
                event
                history={props.history}
                match={props.match}
              />
              <ModalProvider>
                <Event history={props.history} match={props.match} />
              </ModalProvider>
            </>
          )
        }
      />
      <Route
        exact
        path="/Articles"
        render={(props) => (
          <>
            <HeaderContainer removeWelcome minimal history={props.history} />
            <ArticlesMain history={props.history} match={props.match} />
          </>
        )}
      />
      <Route
        exact
        path="/Articles/:subCat"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <>
              <HeaderContainer removeWelcome minimal history={props.history} />
              <ArticlesMain history={props.history} match={props.match} />
            </>
          )
        }
      />
      <Route
        exact
        path="/Article/:id"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <>
              <HeaderContainer removeWelcome minimal history={props.history} />
              <Article history={props.history} match={props.match} />
            </>
          )
        }
      />
      <Route
        exact
        path="/leagueAccess"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <>
              <ModalProvider>
                <Modal />
                <HeaderContainer
                  removeWelcome
                  minimal
                  history={props.history}
                />
                <LeagueAccess history={props.history} />
              </ModalProvider>
            </>
          )
        }
      />
      <Route
        exact
        path={["/professionalServices", "/professionalServices/:filter"]}
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <>
              <ModalProvider>
                <Modal />
                <HeaderContainer
                  removeWelcome
                  minimal
                  history={props.history}
                />
                <Contacts
                  history={props.history}
                  match={props.match}
                  type={contactTypes?.PROFESSIONAL_SERVICES}
                  titleText={"Professional Services"}
                />
              </ModalProvider>
            </>
          )
        }
      />
      <Route
        exact
        path={["/marketingcontacts", "/marketingcontacts/:filter"]}
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <>
              <ModalProvider>
                <Modal />
                <HeaderContainer
                  removeWelcome
                  minimal
                  history={props.history}
                />
                <Contacts
                  history={props.history}
                  match={props.match}
                  type={contactTypes?.MARKETING_CONTACT}
                  titleText={"Marketing Contacts"}
                />
              </ModalProvider>
            </>
          )
        }
      />
      <Route
        exact
        path="/playerEducation"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <div>
              <HeaderContainer removeWelcome minimal history={props.history} />
              <PlayerEducation history={props.history} />
            </div>
          )
        }
      />
      <Route
        exact
        path="/professionaldevelopment"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <>
              <HeaderContainer removeWelcome minimal history={props.history} />
              <ProfessionalDevelopment />
            </>
          )
        }
      />
      <Route
        exact
        path="/notifications"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : (
            <>
              <ModalProvider>
                <Modal />
                <HeaderContainer
                  removeWelcome
                  minimal
                  history={props.history}
                />
                <Notifications />
              </ModalProvider>
            </>
          )
        }
      />
      <Route
        exact
        path="/parent"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : user?.userType !== userTypes.PARENT ? (
            <Redirect to="/" />
          ) : (
            <>
              <HeaderContainer removeWelcome minimal history={props.history} />
              <Parent />
            </>
          )
        }
      />
      <Route
        exact
        path="/spouse"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : user?.userType !== userTypes.SPOUSE ? (
            <Redirect to="/" />
          ) : (
            <>
              <HeaderContainer removeWelcome minimal history={props.history} />
              <Spouse />
            </>
          )
        }
      />
      <Route
        exact
        path="/notepad"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : user?.userType !== userTypes.AGENT ? (
            <Redirect to="/" />
          ) : (
            <>
              <HeaderContainer removeWelcome minimal history={props.history} />
              <Notepad />
            </>
          )
        }
      />
      <Route
        exact
        path="/Agent/Contract/:id"
        render={(props) =>
          !accessToken ? (
            <Redirect to="/login" />
          ) : user?.userType !== userTypes.AGENT ? (
            <Redirect to="/" />
          ) : (
            <>
              <HeaderContainer history={props.history} />
              <Contract
                history={props.history}
                match={props?.match}
                selectedUser={props?.location?.selectedUser}
              />
            </>
          )
        }
      />

      <Route exact path="/blank" render={Blank} />
    </Router>
  );
};

export default withSnackbar(Navigation);
