import React, { useContext, useMemo } from "react";
import { ModalContext } from "../../Context/ModalContext";
import { contactTypes } from "../../lib/constants";

import { Dialog } from "@material-ui/core";

import Map from "./Map";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  modalContainer: {
    width: "500px",
    height: "600px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "350px",
    padding: "25px 0px",
    width: "auto",
  },
  contentBox1: {
    textAlign: "center",
  },
  contentBox2: {
    textAlign: "left",
    width: "300px",
    color: "red",
  },
  contentBox3: {
    textAlign: "left",
    width: "300px",
  },
  p2a: {
    borderTop: "1px solid lightGrey",
    borderBottom: "1px solid lightGrey",
    padding: "10px",
  },
  p2b: {
    borderBottom: "1px solid lightGrey",
    padding: "10px",
  },
  p2b: {
    borderBottom: "1px solid lightGrey",
    padding: "10px",
  },
  button: {
    backgroundColor: "red",
    borderRadius: "20px",
    color: "white",
    border: "none",
    padding: "10px 15px",
    fontSize: "14px",
    textDecoration: "none",
  },
}));

const Modal = () => {
  const classes = useStyles();
  const { ModalState, showModalHandler } = useContext(ModalContext);
  const { show, contactInfo } = ModalState;

  console.log("contactInfo", contactInfo);

  const phone = useMemo(() => {
    if (!contactInfo?.phone) return null;
    if (contactInfo?.type !== contactTypes.LEAGUE) return contactInfo?.phone;
    return contactInfo?.phone
      .split("")
      .map((char, index) =>
        index > contactInfo?.phone?.length - 8 ? "*" : char
      )
      .join("");
  }, [contactInfo?.phone, contactInfo?.type]);

  return (
    <Dialog open={show} onClose={showModalHandler}>
      <div className={classes.modalContainer}>
        {contactInfo?.lat && (
          <Map
            relatedPins={[]}
            thisPin={[
              parseFloat(contactInfo?.lat),
              parseFloat(contactInfo?.lon),
            ]}
            modal
          />
        )}
        <div className={classes.content}>
          <div className={classes.contentBox1}>
            <h3>{contactInfo.name}</h3>
            <h4>{contactInfo.companyName}</h4>
            <p>{contactInfo.service}</p>
            <p>{contactInfo.location}</p>
          </div>
          <div className={classes.contentBox2}>
            {!!phone && <p className={classes.p2a}>{phone}</p>}
            {!!contactInfo.email && (
              <p className={classes.p2b}>{contactInfo.email}</p>
            )}
          </div>
          {!!contactInfo.description && (
            <div className={classes.contentBox3}>
              <p className={classes.p2b}>{contactInfo.description}</p>
            </div>
          )}
          <a
            href={`http://maps.google.com/?q=${contactInfo.location}`}
            className={classes.button}
          >
            Get Directions
          </a>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
