import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

import arrow from "../../images/arrow-right-white.svg";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../api/auth";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    // height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "34px",
    },
  },
  h1: {
    fontSize: "36px",
    ...setStyles.sofiaProBold,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  form: {
    textAlign: "center",
  },
  inputContainer: {
    textAlign: "left",
    margin: "38px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto 15px",
    },
  },
  h6: {
    padding: "0px 0px 10px 25px",
    fontSize: "12px",
  },
  input: {
    width: "380px",
    height: "61px",
    boxShadow: "0px 4px 10px #05070B33",
    borderRadius: "30px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "302px",
    },
  },
  username: {
    width: "90%",
    height: "30px",
    margin: "auto",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProRegular,
  },
  button: {
    width: "106px",
    height: "35px",
    backgroundColor: "#FF002B",
    borderRadius: "18px",
    border: "none",
    ...setStyles.sofiaProBold,
    fontSize: "17px",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
  arrowImg: {
    paddingLeft: "5px",
    width: "20px",
  },
  p: {
    fontSize: "16px",
    opacity: 0.5,
    position: "absolute",
    bottom: 50,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      bottom: 15,
    },
  },
}));

const RecoverPassword = ({ setView }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [checkEmail, setCheckEmail] = useState(false);

  const onReset = async () => {
    try {
      setLoading(true);
      await dispatch(forgotPassword({ email }));
      setCheckEmail(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error);
      setCheckEmail(false);
    }
  };
  return (
    <Grid item sm={12} md={6} className={classes.container}>
      <h1 className={classes.h1}>Recover Password</h1>
      {checkEmail ? (
        <div>
          <h2 className={classes.h2}>
            Please check your email for further instructions
          </h2>
        </div>
      ) : (
        <form className={classes.form}>
          <div className={classes.inputContainer}>
            <h6 className={classes.h6}>USERNAME / EMAIL</h6>
            <div className={classes.input}>
              <input
                autoComplete="email"
                className={classes.username}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <button
            className={classes.button}
            onClick={(e) => {
              e.preventDefault();
              onReset();
            }}
          >
            Submit <img src={arrow} className={classes.arrowImg} />
          </button>
        </form>
      )}
      <p className={classes.p} onClick={() => setView(0)}>
        Login >
      </p>
    </Grid>
  );
};

export default RecoverPassword;
