import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Cal from "../Standalones/Cal";
import setStyles from "../../setStyles";
import { useReduxQuery } from "../../hooks/useReduxQuery";
import { fetchEvent } from "../../api/events";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "150px 0px 75px",
    width: "100%",
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "auto",
      paddingTop: "110px",
      width: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      paddingBottom: "0px",
    },
  },
  headline: {
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  a: {
    fontSize: "20px",
    color: "#FFFFFF",
    ...setStyles.sofiaProSemiBold,
  },
  h3: {
    fontSize: "36px",
    color: "#FFFFFF",
    ...setStyles.sofiaProBold,
    marginTop: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "26px",
    },
  },
  p2: {
    fontSize: "14px",
    ...setStyles.sofiaProBold,
    color: "#A28C62",
  },
  arrow: {
    transform: "scaleX(-1)",
    paddingLeft: "10px",
    float: "left",
  },
  cal: {
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
    },
  },
  logo: {
    position: "absolute",
    maxWidth: "354px",
    maxHeight: "354px",
    left: "55%",
    marginTop: "-75px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-25px",
      left: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      position: "static",
      marginTop: "0px",
    },
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },
  },
  date: {
    fontSize: "14px",
    ...setStyles.sofiaProBold,
    color: "#FFFFFF",
  },
}));

const WelcomeHeader = ({ match }) => {
  const classes = useStyles();
  const id = match?.params?.id;
  const { event } = useReduxQuery("Event", () => fetchEvent(id), {
    selector: (state) => state.events.data[id],
  });

  const d = new Date(event?.startDate);
  const day = d.getUTCDay();
  const month = d.getUTCMonth();

  return (
    <div className={classes.container}>
      <div className={classes.headline}>
        <h3 className={classes.h3}>{event?.title}</h3>
        <Grid container alignItems="center">
          <div className={classes.cal}>
            <Cal month={months[month]} day={d.getUTCDate()} />
          </div>
          <div style={{ paddingLeft: "16px" }}>
            <p className={classes.date}>
              {days[day] + ", " + months[month] + " " + d.getUTCDate()}
            </p>
            {event?.city || event?.state ? (
              <p className={classes.p2}>
                {event.city && event.state ? `${event.city}, ${event.state}` : event.city || event.state}
              </p>
            ) : null}
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default WelcomeHeader;
