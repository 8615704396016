import React, { useState } from "react";
import { Box, Grid, Modal } from "@material-ui/core";
import topLeftImg from "../../images/card-status-top-left.png";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { HelpOutline } from "@material-ui/icons";
import { numberFormatter } from "../../utils/utils";

const Stats = ({ user, selected, ...props }) => {
  const classes = useStyles();
  const { parent } = props;
  const type = selected?.type || user?.type;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [openStatus, setStatusOpen] = useState(false);
  const handleStatusOpen = () => setStatusOpen(true);
  const handleStatusClose = () => setStatusOpen(false);

  const [openPaycheck, setPaycheckOpen] = useState(false);
  const handlePaycheckOpen = () => setPaycheckOpen(true);
  const handlePaycheckClose = () => setPaycheckOpen(false);

  return (
    <>
      <Grid item sm={12} md={6} className={classes.statsGridItem}>
        <div className={classes.statsContainer}>
          <div className={classes.innerContainer}>
            <div className={classes.topLeftImg} />
            <div className={classes.topRightImg} />
            <div
              className={classes.innerBox1}
              style={parent ? { borderRight: "none" } : {}}
            >
              <div className={classes.innerBox3}>
                <p className={classes.p}>PLAYER STATUS</p>
                <p className={classes.icon} onClick={handleStatusOpen}>
                  <HelpOutline fontSize="small" />
                </p>
              </div>
              <h3 className={classes.h3}>{selected?.status || user?.status}</h3>
              <p className={classes.p}>{type || "N/A"}</p>
            </div>
            {!parent && (
              <div className={classes.innerBox2}>
                <div className={classes.innerBox3}>
                  <p className={classes.p}>NEXT PAYCHECK</p>
                  <p className={classes.icon} onClick={handlePaycheckOpen}>
                    <HelpOutline fontSize="small" />
                  </p>
                </div>
                <h3 className={classes.h3}>
                  {user?.contract?.nextPaycheck
                    ? `$${numberFormatter(user?.contract?.nextPaycheck)}`
                    : "N/A"}
                </h3>
                <p className={classes.p}>{"(ESTIMATED)"}</p>
              </div>
            )}
          </div>
        </div>
      </Grid>
      <Modal
        open={openStatus}
        onClose={handleStatusClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p>
              Every week of the season spent on the active roster or injured
              reserve is progress towards earning a credited and accrued season,
              which can help you earn certain benefits and rights to free
              agency.
            </p>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openPaycheck}
        onClose={handlePaycheckClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p>
              As long as you're on the active roster or injured reserve, you can
              expect to be paid in 36 equal installments. This does not include
              certain bonuses and/or performance based pay.
            </p>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Stats;

const useStyles = makeStyles((theme) => ({
  statsGridItem: {
    width: "100%",
    maxWidth: "480px",
    [theme.breakpoints.down("sm")]: {
      margin: "auto auto 20px",
    },
  },
  statsContainer: {
    width: "480px",
    // height: "150px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "75px auto auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  contractStatsContainer: {
    margin: "auto",

    [theme.breakpoints.down("sm")]: {
      margin: "75px auto auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  innerContainer: {
    height: "150px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px grey",
    display: "flex",
    position: "relative",
  },
  topLeftImg: {
    position: "absolute",
    backgroundImage: `url(${topLeftImg})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "125px",
    height: "125px",
  },
  topRightImg: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundImage: `url(${topLeftImg})`,
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "125px",
    height: "125px",
    transform: "scaleX(-1)",
  },
  innerBox1: {
    width: "50%",
    borderRight: "1px solid lightgrey",
    margin: "25px auto",
    textAlign: "center",
    zIndex: 2,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  innerBox2: {
    width: "50%",
    margin: "25px auto",
    textAlign: "center",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  innerBox3: {
    textAlign: "center",
    zIndex: 2,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  h3: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "25px",
  },
  p: {
    ...setStyles.sofiaProRegular,
    color: "#a28c62",
    fontSize: "12px",
  },
  icon: {
    ...setStyles.sofiaProRegular,
    padding: "0px 10px",
    color: "#a28c62",
    fontSize: "12px",
  },
  p1: {
    ...setStyles.sofiaProBold,
    fontSize: "18px",
  },
  p2: {
    ...setStyles.sofiaProBold,
    fontSize: "16px",
    color: "#9b9c9d",
  },
  p3: {
    ...setStyles.sofiaProBold,
    fontSize: "24px",
  },
  p4: {
    ...setStyles.sofiaProBold,
    fontSize: "12px",
    color: "#9b9c9d",
  },
  a: {
    height: "44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    ...setStyles.sofiaProBold,
    textDecoration: "none",
    fontSize: "14px",
  },
}));
