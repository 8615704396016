import React from "react";
import { Grid } from "@material-ui/core";
import StatsA from "../Standalones/StatsA";
import StatsB from "../Standalones/StatsB";
import StatsC from "../Standalones/StatsC";
import StatsD from "../Standalones/StatsD";

import { makeStyles } from "@material-ui/core/styles";
import { useReduxQuery } from "../../hooks/useReduxQuery";
import { fetchUser } from "../../api/user";
import { userTypes } from "../../utils/constants";
import AllUsers from "../Standalones/AllUsers";
import ExpandableSection from "../Standalones/ExpandableSection";
import { upperFirstLetter } from "../../lib/utils";
import setStyles from "../../setStyles";
import Arrow from "../../images/Icons/Arrow";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
const channelId = "C076ZB13H0W";
const teamId = "T073K9R0KAP";
const useStyles = makeStyles((theme) => ({
  container1: {
    maxWidth: "480px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "90%",
    },
  },
  arrow: {
    paddingLeft: "5px",
  },
  container2: {
    // height: "321px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  contractContainer1: {
    margin: "30px auto auto",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      height: "100%",
      width: "90%",
    },
  },
  contractContainer2: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  gridContainer: {
    height: "150px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  link: {
    color: "black",
    textDecoration: "none",
    cursor: "pointer",
  },
  CBAConnectContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    maxWidth: "100%",
    height: "50px",
    backgroundColor: "#43184720",
    borderRadius: "46px",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
    marginTop: "30px",
  },
  CBAConnect: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    maxWidth: "100%",
    height: "50px",
    color: "#431847",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
  },
}));

const calculatePercentage = (value, percentages) => {
  if (value === null || value === undefined || value === "" || value < 0) {
    return "N/A";
  }
  return value === 0 ? 0 : Math.min(percentages[value - 1] || 100, 100);
};

const StatsContainer = ({ contract2, selected, user, ...props }) => {
  const classes = useStyles();
  const contract = selected || user?.contract;
  const isContract =
    contract ||
    user?.userType !== userTypes.PLAYER ||
    user?.userType !== userTypes.COLLEGE
      ? true
      : false;
  const { parent, page } = props;

  return (
    <Grid
      item
      sm={12}
      md={isContract && 6}
      className={contract2 ? classes.container1 : classes.contractContainer1}
      style={{
        maxWidth: user?.userType === userTypes.AGENT && page ? "100%" : "auto",
      }}
    >
      {/* <a href="/contract" className={classes.link}> */}
      {(user?.userType === userTypes.PLAYER ||
        user?.userType === userTypes.COLLEGE ||
        selected) && (
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          className={
            isContract ? classes.container2 : classes.contractContainer2
          }
        >
          <div style={{ marginBottom: "30px" }}>
            {isContract && (
              <StatsA user={user} selected={selected} parent={parent} />
            )}
          </div>
          {user?.userType === userTypes.PLAYER && (
            <Grid item>
              <Grid
                container
                justifyContent="space-between"
                className={false && classes.gridContainer}
              >
                <StatsB
                  numSeas={contract?.totalCredited || 0}
                  titleA="Credited Seasons"
                  titleB="Credited Games"
                  perc={
                    contract?.currentCredited
                      ? calculatePercentage(
                          contract?.currentCredited,
                          [33, 67, 100]
                        )
                      : "0"
                  }
                  color="teal"
                  user={user}
                />
                <StatsB
                  numSeas={contract?.totalAccrued || 0}
                  titleA="Accrued Seasons"
                  titleB="Accrued Games"
                  perc={
                    contract?.currentAccrued
                      ? calculatePercentage(
                          contract?.currentAccrued,
                          [17, 33, 50, 67, 83, 100]
                        )
                      : "0"
                  }
                  color="gold"
                  user={user}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {user?.userType !== userTypes.PLAYER &&
        user?.userType !== userTypes.COLLEGE &&
        !selected &&
        user?.userType !== userTypes.PARENT &&
        user?.userType !== userTypes.SPOUSE &&
        user?.userType !== userTypes.AGENT && (
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            className={
              isContract ? classes.container2 : classes.contractContainer2
            }
          >
            <StatsD user={user} />
            <Grid item>
              {user?.userType !== userTypes.AGENT && (
                <Grid
                  container
                  justifyContent="space-between"
                  className={false && classes.gridContainer}
                >
                  <StatsC
                    numSeas={
                      (user?.executive &&
                        `${user.executive.yearsWithCurrentTeam}`) ||
                      (user?.coach && `${user.coach.yearsWithCurrentTeam}`) ||
                      (user?.reporter &&
                        `${user.reporter.yearsWithCurrentTeam}`)
                    }
                    titleA={`Years with ${
                      (user?.team?.city &&
                        user?.team.name &&
                        user?.team?.city + " " + user?.team?.name) ||
                      "Team"
                    }`}
                    color="teal"
                    user={user}
                  />
                  <StatsC
                    numSeas={
                      (user?.executive && `${user.executive.yearsActive}`) ||
                      (user?.coach && `${user.coach.yearsActive}`) ||
                      (user?.reporter && `${user.reporter.yearsActive}`)
                    }
                    titleA={
                      user?.userType === userTypes.EXECUTIVE
                        ? `Years Scouting`
                        : user?.userType === userTypes.REPORTER
                        ? `Years Reporting`
                        : `Years ${upperFirstLetter(user?.userType)}ing`
                    }
                    color="gold"
                    user={user}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      {user?.userType === userTypes.PARENT && (
        <ExpandableSection placeholder="My Players">
          <AllUsers selected={true} fullWidth={false} />
        </ExpandableSection>
      )}
      {user?.userType === userTypes.SPOUSE && (
        <ExpandableSection placeholder="My Spouse">
          <AllUsers selected={true} fullWidth={false} />
        </ExpandableSection>
      )}
      {user?.userType === userTypes.AGENT && (
        <Grid container direction="column" alignItems="center">
          <StatsD user={user} />
          <div style={{ width: "100%" }}>
            <ExpandableSection placeholder="My Clients">
              <AllUsers selected={true} fullWidth={page ? true : false} />
            </ExpandableSection>
          </div>
          {page && (
            <div style={{ width: "100%" }}>
              <ExpandableSection placeholder="All Players">
                <AllUsers selected={false} fullWidth={true} />
              </ExpandableSection>
            </div>
          )}
        </Grid>
      )}
      <button className={classes.CBAConnectContainer}>
        <a
          href={`slack://channel?team=${teamId}&id=${channelId}`}
          className={classes.CBAConnect}
        >
          Connect and Collaborate Live
          <div className={classes.arrow}>
            <Arrow color="slack" />
          </div>
        </a>
      </button>
    </Grid>
  );
};

export default StatsContainer;
