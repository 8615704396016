import React from "react";

import EventsContainer from "../Components/Events/EventsContainer";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";

const Events = () => {
  const classes = useStyles();

  return (
    <div className={classes.eventContainer}>
      <EventsContainer />
    </div>
  );
};

export default Events;

const useStyles = makeStyles((theme) => ({
  eventContainer: {
    width: "100%",
    height: "100%",
    maxWidth: setStyles.maxWidth,
    margin: "-225px auto auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));
