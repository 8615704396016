import React from "react";
import { Grid } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker, faStar } from "@fortawesome/free-solid-svg-icons";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

const useStyles = makeStyles((theme) => ({
  eventCardGridItem: {
    maxWidth: "300px",
    height: "88px",
    boxShadow: "1px 1px 5px grey",
    borderRadius: "6px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },

  lowerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    margin: "auto",
    height: "100%",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardInnerContainer: {
    width: "225px",
    paddingLeft: "18px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  headingCal: {
    display: "flex",
    flexDirection: "column",
  },
  date: {
    color: "red",
    fontSize: "11px",
  },
  h4: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "16px",
    lineHeight: "1.182",
    width: "80%",
    color: "#505255",
  },
  p1: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "12px",
    color: "#05070B",
    opacity: 0.5,
    paddingTop: "5px",
  },
  p2: {
    ...setStyles.sofiaProRegular,
    fontSize: "16px",
    lineHeight: "1.375",
    color: "#505255",
  },
  link: {
    textDecoration: "none",
    width: "auto",
  },
  iconContainer: {
    position: "relative",
  },
  locationIconRed: {
    color: "red",
    opacity: 0.1,
  },
  iconText: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    marginTop: -3,
    color: "red",
    fontSize: "24px",
    fontWeight: 600,
    top: 0,
  },
}));

const EventCardSmall = ({ heading, location, date, id, index }) => {
  const classes = useStyles();

  const d = new Date(date);
  const day = d.getUTCDay();
  const month = d.getUTCMonth();

  return (
    <Grid item sm={12} md={4} className={classes.eventCardGridItem}>
      <div className={classes.lowerContainer}>
        <a href={`/event/${id}`} className={classes.link}>
          <div className={classes.cardInnerContainer}>
            <div className={classes.headingCal}>
              <p className={classes.date}>
                {days[day] + ", " + months[month] + " " + d.getUTCDate()}
              </p>
              <h4 className={classes.h4}>{heading}</h4>
            </div>
            <p className={classes.p1}>{location}</p>
          </div>
        </a>
        <div className={classes.iconContainer}>
          <FontAwesomeIcon
            className={classes.locationIconRed}
            icon={faMapMarker}
            size="3x"
          />
          <p className={classes.iconText}>{index}</p>
        </div>
      </div>
    </Grid>
  );
};

export default EventCardSmall;
