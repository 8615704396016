import React from "react";
import ContactCard from "../Standalones/ContactCard";

const Contacts = ({ contact }) => {
  return (
    <ContactCard
      name={`${contact.firstName} ${contact.lastName}`}
      team={contact?.team?.name}
      title={contact.role}
      phone={contact.phone}
      email={contact.email}
      logoUrl={contact?.team?.logoUrl}
      lat={contact?.lat}
      lon={contact?.lon}
      contact={contact}
    />
  );
};

export default Contacts;
