import React from "react";

const CareerIcon = ({ active }) => {
  return (
    <svg
      width="19px"
      height="19px"
      viewBox="0 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Contract</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Dashboards-v1"
        stroke="none"
        strokeWidth="1"
        fill={active ? "#FF002B" : "#FFFFFF"}
        fillRule="evenodd"
      >
        <g
          id="Dashboard---Nav-Tray"
          transform="translate(-324.000000, -26.000000)"
        >
          <g id="Header" transform="translate(-104.000000, -23.000000)">
            <g id="Primary-Nav" transform="translate(356.000000, 47.000000)">
              <g id="Contract" transform="translate(58.000000, 2.000000)">
                <g id="Group-4" transform="translate(14.000000, 0.000000)">
                  <g id="Contract" transform="translate(0.186647, 0.232471)">
                    <path
                      d="M1.16347059,11.6425294 L3.49261912,11.6425294 C3.80150882,11.6425294 4.09729412,11.7647721 4.31557059,11.9830765 C4.53384706,12.2013809 4.65611765,12.4982559 4.65611765,12.8071176 L4.65611765,18.6278235 L3.61488617e-13,18.6278235 L3.61488617e-13,12.8071176 C3.61488617e-13,12.1642471 0.520627941,11.6425294 1.16349853,11.6425294 L1.16347059,11.6425294 Z"
                      id="Fill-1"
                    ></path>
                    <path
                      d="M8.14958824,4.65723529 L10.4787368,4.65723529 C10.7876265,4.65723529 11.0834118,4.77947794 11.3016882,4.99778235 C11.5199647,5.21608676 11.6422353,5.51296176 11.6422353,5.82182353 L11.6422353,18.6278235 L6.98611765,18.6278235 L6.98611765,5.82182353 C6.98611765,5.17895294 7.50674559,4.65723529 8.14961618,4.65723529 L8.14958824,4.65723529 Z"
                      id="Fill-2"
                    ></path>
                    <path
                      d="M17.4648544,-2.77111667e-13 C17.7737441,-2.77111667e-13 18.0695294,0.123335147 18.2878059,0.341636765 C18.5060824,0.559938382 18.6283529,0.855698529 18.6283529,1.16458824 L18.6283529,18.6278235 L13.9722353,18.6278235 L13.9722353,1.16458824 C13.9722353,0.521717647 14.4928632,-2.77111667e-13 15.1357338,-2.77111667e-13 L17.4648544,-2.77111667e-13 Z"
                      id="Fill-3"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CareerIcon;
