import React from "react";

const ParentIcon = ({ active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      id="person"
      fill={active ? "#FF002B" : "#FFFFFF"}
    >
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"></path>
    </svg>
  );
};

export default ParentIcon;
