import React, { useEffect } from "react";

import Header from "../Components/PlayerEducation/Header";
import PopularArticles from "../Components/PlayerEducation/PopularArticles";

import Contract from "../images/Icons/Contract";
import LeagueContact from "../images/Icons/LeagueContact";
import Legal from "../images/Icons/Legal";
import Medical from "../images/Icons/Medical";
import WorkIcon from "@material-ui/icons/Work";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DraftsIcon from "@material-ui/icons/Drafts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import FolderIcon from "@material-ui/icons/Folder";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchArticles } from "../api/articles";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto 50px",
  },
}));

const PlayerEducation = () => {
  const classes = useStyles();
  const { articles } = useReduxQuery("Articles", fetchArticles);

  return (
    <div className={classes.container}>
      <Header />
      {section.map((s, i) => (
        <PopularArticles
          title={s.title}
          icon={s.icon}
          subheading={s.subheading}
          articles={Object.values(articles || [])
            ?.filter((a) =>
              a.tags.some((t) => t?.toLowerCase() === s.type?.toLowerCase())
            )
            ?.slice(0, 2)}
          seeAll={s.seeAll}
          color={s.color}
          backgroundColor={s.backgroundColor}
          key={i}
          type={s.type}
        />
      ))}
    </div>
  );
};

export default PlayerEducation;

const section = [
  {
    title: "Legal Help",
    icon: <Legal color="#9F9F9F" />,
    color: "#9F9F9F",
    backgroundColor: "#9F9F9F20",
    subheading:
      "Get in touch with an attorney while learning more about an individuals rights when dealing with a legal issue and the proper steps to take to ensure the best possible outcome.",
    articles: [],
    type: "legal",
    seeAll: {
      title: "See All Legal Help Articles",
      href: "#",
    },
  },
  {
    title: "Career",
    icon: <WorkIcon fontSize="large" style={{ color: "#4B7BEC" }} />,
    color: "#4B7BEC",
    backgroundColor: "#4B7BEC20",
    type: "league",
    subheading:
      "Discover a range of specialized services and expertise tailored to support and enhance the professional careers of athletes, including legal assistance, financial planning, career coaching, and other essential resources.",
    articles: [],
    seeAll: {
      title: "See All Career Articles",
      href: "#",
    },
  },
  {
    title: "Strategic Partners",
    icon: (
      <FontAwesomeIcon
        icon={faHandshake}
        color="#E48C00"
        style={{ color: "#E48C00", fontSize: "32px" }}
      />
    ),
    color: "#E48C00",
    backgroundColor: "#E48C0020",
    type: "strategicpartners",
    subheading:
      "Discover the benefits of forming strategic partnerships with other organizations and individuals to help you achieve your goals and objectives more effectively.",
    articles: [],
    seeAll: {
      title: "See All Strategic Partners Articles",
      href: "#",
    },
  },
  {
    title: "Contract Repository",
    icon: <FolderIcon fontSize="large" style={{ color: "#3AA6FF" }} />,
    color: "#3AA6FF",
    backgroundColor: "#3AA6FF20",
    type: "contractrepository",
    subheading:
      "Access a comprehensive repository of contract-related resources, including sample contracts, negotiation tips, and expert advice to help you understand and navigate the complexities of professional sports contracts.",
    articles: [],
    seeAll: {
      title: "See All Contract Repository Articles",
      href: "#",
    },
  },
  {
    title: "League Help",
    icon: <LeagueContact color="#0AC9BA" />,
    color: "#0AC9BA",
    backgroundColor: "#0AC9BA20",
    type: "league",
    subheading:
      "Get in touch with NFL front office executives while also learning about different things to consider when pursuing information and/or NFL employment.",
    articles: [],
    seeAll: {
      title: "See All League Help Articles",
      href: "#",
    },
  },
  {
    title: "Medical Help",
    icon: <Medical color="#FF4D4D" />,
    color: "#FF4D4D",
    backgroundColor: "#FF4D4D20",
    type: "medical",
    subheading:
      "Get in touch with a specialized doctor while learning more about a player's collectively bargained rights, along with things to watch out for and consider when dealing with an injury.",
    articles: [],
    seeAll: {
      title: "See All Medical Articles",
      href: "#",
    },
  },
  {
    title: "Benefits",
    icon: <LocalOfferIcon fontSize="large" style={{ color: "#32CF52" }} />,
    color: "#32CF52",
    backgroundColor: "#32CF5220",
    type: "benefits",
    subheading:
      "Access information and insights about the valuable perks and support systems available to professional athletes beyond their salary, including health coverage, retirement plans, and more.",
    articles: [],
    seeAll: {
      title: "See All Benefit Articles",
      href: "#",
    },
  },
  {
    title: "Pre-Draft",
    icon: <DraftsIcon fontSize="large" style={{ color: "#F1CB00" }} />,
    color: "#F1CB00",
    backgroundColor: "#F1CB0020",
    type: "pre-draft",
    subheading:
      "Gain valuable resources and guidance to navigate the important phase of preparing for the draft, including expert insights, training tips, and strategies for maximizing your chances of success.",
    articles: [],
    seeAll: {
      title: "See All Pre-Draft Articles",
      href: "#",
    },
  },
  {
    title: "Off-Season",
    icon: <CalendarTodayIcon fontSize="large" style={{ color: "#FF89D3" }} />,
    color: "#FF89D3",
    backgroundColor: "#FF89D320",
    type: "off-season",
    subheading:
      "Explore resources and guidance to maximize the strategic opportunities and preparations during the crucial period between regular sports seasons.",
    articles: [],
    seeAll: {
      title: "See All Off-Season Articles",
      href: "#",
    },
  },
  {
    title: "Contract Help",
    icon: <Contract color="#FFAF40" />,
    type: "contract",
    color: "#FFAF40",
    backgroundColor: "#FFAF4020",
    subheading:
      "Learn more about the different elements that can potentially be included in a contract, along with the different factors that contribute towards determining a player's contract value.",
    articles: [],
    seeAll: {
      title: "See All Contract Help Articles",
      href: "#",
    },
  },
];
