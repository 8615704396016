import React from "react";

import { Grid } from "@material-ui/core";

import Arrow from "../../images/Icons/Arrow";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { truncate } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  ArticleCardGridItem: {
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  ArticleCard: {
    width: "100%",
    height: "288px",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px grey",
    padding: "18px",
    backgroundColor: "white",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  p1: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "12px",
    color: "#a28c62",
    padding: "8px 0px 10px",
  },
  p2: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "18px",
    color: "#011627",
    padding: "10px 0px 0px",
    lineHeight: "1.333",
  },
  p3: {
    ...setStyles.sofiaProRegular,
    fontSize: "16px",
    color: "#05070b",
    padding: "12px 0px",
    lineHeight: "1.375",
    opacity: "0.75",
  },
  p4: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "14px",
    color: "#9b9c9d",
    position: "absolute",
    bottom: 0,
    paddingBottom: "26px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  img: {
    paddingLeft: "5px",
    width: "19px",
    opacity: "0.6",
  },
  arrow: {
    paddingLeft: "5px",
  },
}));

const ArticleCard = ({ subject, heading, content, id }) => {
  const classes = useStyles();

  return (
    <Grid item sm={12} md={4} className={classes.ArticleCardGridItem}>
      <div className={classes.ArticleCard}>
        <p className={classes.p1}>
          {subject
            ?.replace(/contractrepository/gi, "Contract Repository")
            ?.replace(/strategicpartners/gi, "Strategic Partners")
            ?.replace(/professionaldevelopment/gi, "Professional Development")}
        </p>
        <p className={classes.p2}>{heading}</p>
        <p className={classes.p3}>{truncate(content)}</p>
        {!subject.includes("CONTRACT REPOSITORY") ? (
          <a className={classes.p4} href={`/article/${id}`}>
            LEARN MORE{" "}
            <span className={classes.arrow}>
              <Arrow color="lightGrey" />
            </span>
          </a>
        ) : (
          <a className={classes.p4} href={`/article/${id}`}>
            FILE LINKS{" "}
            <span className={classes.arrow}>
              <Arrow color="lightGrey" />
            </span>
          </a>
        )}
      </div>
    </Grid>
  );
};

export default ArticleCard;
