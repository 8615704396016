import React from "react";

import Arrow from "../../../images/Icons/Arrow";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";

const useStyles = makeStyles((theme) => ({
  a: {
    textDecoration: "none",
  },
  main: {
    width: "380px",
    height: "50px",
    backgroundColor: "white",
    boxShadow: "2px 3px 10px grey",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "24px",

    [theme.breakpoints.down("sm")]: {
      width: "300px",
      margin: "auto auto 10px",
    },
  },
  orange: {
    backgroundColor: "#ffa428",
  },
  blue: {
    backgroundColor: "#3f6ede",
  },
  red: {
    backgroundColor: "#ff3e3e",
  },
  grey: {
    backgroundColor: "#404040",
  },
  lightgrey: {
    backgroundColor: "#D3D3D3",
  },
  teal: {
    backgroundColor: "#1dcec0",
  },
  purple: {
    backgroundColor: "#745ae9",
  },
  lime: {
    backgroundColor: "#2BF62B",
  },
  pink: {
    backgroundColor: "#FF6EC7",
  },
  yellow: {
    backgroundColor: "#F1CB00",
  },
  green: {
    backgroundColor: "#4CAF50",
  },
  forestGreen: {
    backgroundColor: "#228B22",
  },
  navyBlue: {
    backgroundColor: "#000080",
  },
  burntOrange: {
    backgroundColor: "#CC5500",
  },
  gold: {
    backgroundColor: "#F3EEE299",
    height: "35px",
    borderRadius: "25px",
    color: "#A28C62",
    margin: "auto",
    width: "150px",
    marginTop: "25px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchBar: {
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100px",
    width: "180px",
    padding: "15px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "140px",
      margin: "10px auto 10px",
    },
  },
  arrowImg: {
    paddingLeft: "5px",
    width: "20px",
  },
  searchImg: {
    marginLeft: "-40px",
    paddingRight: "25px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      paddingLeft: "5px",
      paddingRight: "10px",
      fontSize: "0.9rem",
    },
  },
  p: {
    color: "white",
    ...setStyles.sofiaProRegular,
    fontSize: "14px",
    display: "flex",
    fontWeight: "500",
  },
  pMain: {
    color: "#A28C62",
    fontSize: "20px",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "10px",
      fontSize: "18px",
    },
  },
}));

const ColoredButton = ({ colors, color, text, url, children }) => {
  const classes = useStyles();

  const getClassName = () => {
    switch (color) {
      case "main":
        return classes.main;
      case "orange":
        return classes.orange;
      case "blue":
        return classes.blue;
      case "red":
        return classes.red;
      case "grey":
        return classes.grey;
      case "lightgrey":
        return classes.lightgrey;
      case "teal":
        return classes.teal;
      case "lime":
        return classes.lime;
      case "pink":
        return classes.pink;
      case "purple":
        return classes.purple;
      case "yellow":
        return classes.yellow;
      case "gold":
        return classes.gold;
      case "green":
        return classes.green;
      case "forestGreen":
        return classes.forestGreen;
      case "burntOrange":
        return classes.burntOrange;
      case "navyBlue":
        return classes.navyBlue;
      case "brown":
        return classes.brown;
      default:
        return null;
    }
  };

  return (
    <a href={url} className={classes.a}>
      <div
        style={{
          background:
            color == "main"
              ? "white"
              : `linear-gradient(145deg, ${colors?.[0]}, ${colors?.[1]})`,
          boxShadow: `0px 2px 10px ${colors?.[1]}80`,
        }}
        className={
          color == "main" || color == "gold"
            ? getClassName()
            : `${classes.searchBar} ${getClassName()}`
        }
      >
        {children}
        <p
          className={
            color == "main" || color == "gold" ? `${classes.pMain}` : classes.p
          }
        >
          {text}{" "}
          {color == "gold" && (
            <Arrow color="gold" className={classes.arrowImg} />
          )}
        </p>
      </div>
    </a>
  );
};

export default ColoredButton;
