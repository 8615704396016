import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const Header = ({ title, description }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h1 className={classes.h1}>{title}</h1>
      {description !== undefined && (
        <p className={classes.subheading}>{description}</p>
      )}
    </div>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    maxWidth: "800px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  h1: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fontSize: "36px",
    paddingBottom: "20px",
    width: "100%",
  },
  subheading: {
    color: "#404040",
    opacity: 0.75,
    fontSize: "30px",
    letterSpacing: "0.18px",
    ...setStyles.sofiaProLight,
    paddingBottom: "30px",
    lineHeight: "30px",
    width: "100%",
  },
}));
