import React from "react";
import { Grid } from "@material-ui/core";

import greenBG from "../../images/event-bg-green.png";
import goldBG from "../../images/event-bg-gold.png";
import redBG from "../../images/event-bg-red.png";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

const useStyles = makeStyles((theme) => ({
  eventCardGridItem: {
    maxWidth: "300px",
    height: "235px",
    boxShadow: "1px 1px 5px grey",
    borderRadius: "6px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  upperContainer: {
    height: "50%",
    backgroundColor: "white",
  },
  lowerContainer: {
    width: "100%",
    height: "50%",
    margin: "auto",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardInnerContainer: {
    width: "100%",
    padding: "14px 18px",
  },
  headingCal: {
    display: "flex",
    flexDirection: "column",
  },
  date: {
    color: "red",
    fontSize: "11px",
  },
  h4: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "18px",
    lineHeight: "1.182",
    width: "80%",
    color: "#505255",
  },
  p1: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "12px",
    color: "#a28c62",
    paddingBottom: "18px",
    paddingTop: "5px",
  },
  p2: {
    ...setStyles.sofiaProRegular,
    fontSize: "16px",
    lineHeight: "1.375",
    color: "#505255",
  },
  link: {
    textDecoration: "none",
  },
}));

const EventCard = ({ heading, location, date, tags, id, coverUrl }) => {
  const classes = useStyles();

  const d = new Date(date);
  const day = d.getUTCDay();
  const month = d.getUTCMonth();

  const setColor = () => {
    const category = tags?.[0];
    if (category === "other") {
      return greenBG;
    } else if (category === "league") {
      return redBG;
    } else if (category === "networking") {
      return goldBG;
    }
  };

  return (
    <Grid item sm={12} md={4} className={classes.eventCardGridItem}>
      <div className={classes.upperContainer}>
        <img
          src={coverUrl ? coverUrl : require("../../images/stock-football.jpg")}
          alt={setColor()}
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className={classes.lowerContainer}>
        <a href={`/event/${id}`} className={classes.link}>
          <div className={classes.cardInnerContainer}>
            <div className={classes.headingCal}>
              <p className={classes.date}>
                {days[day] + ", " + months[month] + " " + d.getUTCDate()}
              </p>
              <h4 className={classes.h4}>{heading}</h4>
            </div>
            <p className={classes.p1}>{location}</p>
          </div>
        </a>
      </div>
    </Grid>
  );
};

export default EventCard;
