import React, { useEffect } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import Routes from "./Routes";

import "./App.css";
import { SnackbarProvider } from "notistack";
import { useLocation } from "react-router-dom";

const theme = createTheme();

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <div className="App" style={{ postition: "relative" }}>
        <MuiThemeProvider theme={theme}>
          <Routes />
        </MuiThemeProvider>
      </div>
    </SnackbarProvider>
  );
}

export default App;
