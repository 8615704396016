import { makeStyles } from "@material-ui/core/styles";

const Badge = ({ nb }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <p className={classes.text}>{nb}</p>
      </div>
    </div>
  );
};

export default Badge;

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "red",
    borderRadius: 30,
    position: "absolute",
    right: 14,
    top: -9,
    width: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    width: 20,
    height: 20,
    borderRadius: 20,
    backgroundColor: "red",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    alignSelf: "center",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
  },
}));
