import { loadSettings } from "../redux/settings";
import { axiosHandler } from "../lib/utils";

export const fetchSettings = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `settings`,
      method: "GET",
      state: getState(),
    });
    await dispatch(loadSettings(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};
