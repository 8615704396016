import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const useStyles = makeStyles((theme) => ({
  darkenShow: {
    maxHeight: "100%",
    opacity: "0.4",
    transition: "opacity 0.5s linear",
    zIndex: 9998,
    position: "fixed",
    top: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "#05070b",
  },
  darkenHide: {
    transition: "opacity 0.5s linear, max-height 0.5s linear 1s",
    maxHeight: 0,
    opacity: 0,
    top: 0,
    position: "fixed",
    width: "100%",
    height: "100vh",
    backgroundColor: "#05070b",
    zIndex: 9996,
  },
}));

const Darken = ({ showMore, closeMenu }) => {
  const classes = useStyles();
  return (
    <div
      className={showMore ? classes.darkenShow : classes.darkenHide}
      onClick={closeMenu}
    ></div>
  );
};

export default Darken;
