const fontFamily = "sofia-pro, sans-serif";

const setStyles = {
  maxWidth: "960px",
  setFlexRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  containerRow: {
    maxWidth: "960px",
    margin: "auto auto 50px",
  },
  setSecondaryHeading: {
    marginTop: "0px",
    paddingTop: "25px",
  },
  setCardsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexFirection: "row",
  },
  sofiaProBlack: {
    fontFamily: fontFamily,
    fontWeight: 900,
    fontStyle: "normal",
  },
  sofiaProBold: {
    fontFamily: fontFamily,
    fontWeight: 700,
    fontStyle: "normal",
  },
  sofiaProRegular: {
    fontFamily: fontFamily,
    fontWeight: 400,
    fontStyle: "normal",
  },
  sofiaProRegularItalic: {
    fontFamily: fontFamily,
    fontWeight: 400,
    fontStyle: "italic",
  },
  sofiaProSemiBold: {
    fontFamily: fontFamily,
    fontWeight: 600,
    fontStyle: "normal",
  },
  sofiaProSemiBoldItalic: {
    fontFamily: fontFamily,
    fontWeight: 600,
    fontStyle: "italic",
  },
  sofiaProLight: {
    fontFamily: fontFamily,
    fontWeight: 200,
    fontStyle: "normal",
  },
};

export default setStyles;
