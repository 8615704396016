import React from "react";

import { Grid } from "@material-ui/core";

import BoxA from "./BoxA";
import BoxB from "./BoxB";

import { makeStyles } from "@material-ui/core/styles";
import numberFormatter from "../../../utils/numberFormatter";

const BoxesContainer = ({ user }) => {
  const classes = useStyles();

  let marketVal = [
    [
      "TOTAL SALARY",
      user?.contract?.totalSalary
        ? `$${numberFormatter(user?.contract?.totalSalary)}`
        : "N/A",
    ], //5 YRS.
    [
      "AVG. SALARY",
      user?.marketValue?.averageAnnualSalary
        ? `$${numberFormatter(user?.marketValue?.averageAnnualSalary)}`
        : "N/A",
    ],
    ["NFL RANK", user?.marketValue?.nflRank || "N/A"],
    [
      `${user?.contract?.position} RANK`,
      user?.marketValue?.positionRank || "N/A",
    ],
  ];

  let currentCon = [
    ["FREE AGENT", user?.contract?.freeAgentYear || "N/A"],
    [
      "AVG. SALARY",
      user?.contract?.averageSalary
        ? `$${numberFormatter(user?.contract?.averageSalary)}`
        : "N/A",
    ],
    ["NFL RANK", user?.contract?.nflRank || "N/A"],
    [`${user?.contract?.position} RANK`, user?.contract?.positionRank || "N/A"],
  ];

  const formatAvg = (value) => {
    const avg = (value || 1).toString();
    // Nine Zeroes for Billions
    return Math.abs(Number(avg)) >= 1.0e9
      ? (Math.abs(Number(avg)) / 1.0e9).toFixed(1) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(avg)) >= 1.0e6
      ? (Math.abs(Number(avg)) / 1.0e6).toFixed(1) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(avg)) >= 1.0e3
      ? (Math.abs(Number(avg)) / 1.0e3).toFixed(1) + "K"
      : Math.abs(Number(avg).toFixed(1));
  };

  return (
    <Grid item sm={12} className={classes.container1}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={classes.container2}
      >
        <Grid
          container
          justifyContent="space-between"
          className={classes.gridContainer}
        >
          <BoxA avg={formatAvg(user?.marketValue?.averageAnnualSalary)} />
          <BoxB title="MARKET VALUE" values={marketVal} color="blue" />
          <BoxB title="CURRENT CONTRACT" values={currentCon} color="green" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BoxesContainer;

const useStyles = makeStyles((theme) => ({
  container1: {
    margin: "30px auto auto",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      height: "100%",
      width: "90%",
    },
  },
  container2: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  gridContainer: {
    height: "150px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
}));
