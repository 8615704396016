import React from "react";
import { Grid } from "@material-ui/core";

import ArticlesContainer from "../Components/Dashboard/ArticlesContainer";
import Events from "../Components/Dashboard/EventsContainer";
import SearchBar from "../Components/Dashboard/SearchBar/SearchBarContainer";
import StatsContainer from "../Components/Dashboard/StatsContainer";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchArticles } from "../api/articles";
import { fetchUser } from "../api/user";

const Dashboard = ({ history }) => {
  const classes = useStyles();
  const { articles } = useReduxQuery("Articles", fetchArticles);
  const { user } = useReduxQuery("User", fetchUser);

  const articleArray = Object.values(articles || {});
  const limitedArticles = articleArray.slice(0, 6);

  // Convert limitedArticles array back to an object
  const limitedArticlesObject = limitedArticles.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

  return (
    <div>
      <Grid container className={classes.searchBarStatsContainer}>
        <SearchBar user={user} />
        <StatsContainer user={user} />
      </Grid>
      <div className={classes.articlesContainer}>
        <ArticlesContainer
          articles={limitedArticlesObject}
          title="Common Player Questions"
          button="View all player questions"
        />
      </div>
      <Events history={history} />
    </div>
  );
};

export default Dashboard;

const useStyles = makeStyles((theme) => ({
  articlesContainer: {
    marginTop: "100px",
  },
  searchBarStatsContainer: {
    maxWidth: setStyles.maxWidth,
    margin: "-75px auto auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "-50px auto auto",
    },
  },
}));
