import React from "react";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import auth from "./auth";
import user from "./user";
import events from "./events";
import articles from "./articles";
import notifications from "./notifications";
import contacts from "./contacts";
import settings from "./settings";
import parent from "./parent";
import agent from "./agent";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  auth,
  user,
  events,
  articles,
  notifications,
  contacts,
  settings,
  parent,
  agent,
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const middleware = [thunk];
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
const persistor = persistStore(store);

export { store, persistor };
