import React from "react";

const HomeIcon = ({ active }) => {
  return (
    <svg
      width="19px"
      height="19px"
      viewBox="0 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>home</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Dashboards-v1"
        stroke="none"
        strokeWidth="1"
        fill={active ? "#FF002B" : "#FFFFFF"}
        fillRule="evenodd"
      >
        <g
          id="Dashboard---Nav-Tray"
          transform="translate(-258.000000, -26.000000)"
          fillRule="nonzero"
        >
          <g id="Header" transform="translate(-104.000000, -23.000000)">
            <g id="Primary-Nav" transform="translate(356.000000, 47.000000)">
              <g id="Home" transform="translate(0.000000, 2.000000)">
                <g id="home" transform="translate(6.000000, 0.000000)">
                  <path
                    d="M18.4877167,8.26408384 C18.4872818,8.263649 18.4868469,8.26321411 18.486412,8.26277923 L10.7359162,0.512573242 C10.4055557,0.182067871 9.96633147,0 9.49913023,0 C9.03192903,0 8.59270476,0.181922922 8.26219939,0.512428293 L0.515762348,8.25872039 C0.513153076,8.26132966 0.510543805,8.26408384 0.50793457,8.26669312 C-0.170471191,8.94901277 -0.169311523,10.0560608 0.511268625,10.7366409 C0.82220459,11.0477219 1.232872,11.2279053 1.67195131,11.2467499 C1.68978118,11.2484894 1.70775603,11.2493591 1.72587584,11.2493591 L2.0347824,11.2493591 L2.0347824,16.9530411 C2.0347824,18.0816879 2.95309448,19 4.08203125,19 L7.11427309,19 C7.4215851,19 7.67091371,18.7508163 7.67091371,18.4433594 L7.67091371,13.9716797 C7.67091371,13.4566422 8.08984375,13.0377121 8.60488128,13.0377121 L10.3933792,13.0377121 C10.9084167,13.0377121 11.3273468,13.4566422 11.3273468,13.9716797 L11.3273468,18.4433594 C11.3273468,18.7508163 11.5765304,19 11.8839874,19 L14.9162292,19 C16.045166,19 16.9634781,18.0816879 16.9634781,16.9530411 L16.9634781,11.2493591 L17.2499161,11.2493591 C17.7169723,11.2493591 18.1561966,11.0674362 18.4868469,10.7369308 C19.1681519,10.0551911 19.1684418,8.94625854 18.4877167,8.26408384 L18.4877167,8.26408384 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HomeIcon;
