import React, { useMemo } from "react";

import Header from "../Components/ArticlesMain/Header";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchArticles } from "../api/articles";
import { capitalizeFirstLetter } from "../lib/utils";
import ArticlesContainer from "../Components/Dashboard/ArticlesContainer";

const ArticlesMain = ({ match }) => {
  const classes = useStyles();
  const { articles } = useReduxQuery("Articles", fetchArticles);
  let tag = match.params.subCat;
  const legalDisclaimer =
    "The information provided is intended for general informational purposes only. We strive for accuracy but do not guarantee completeness or reliability. Any action you take based on the information is at your own risk. This content does not constitute legal or professional advice. For specific inquiries, consult a qualified professional. We are not liable for any losses, damages, or injuries resulting from reliance on the content. We reserve the right to modify, add, or remove information without notice. ";
  const getDescription = (tag) => {
    switch (tag) {
      case "medical":
        return "Our Knowledge-base provides helpful articles on how to handle common player issues during your career.";
      case "contract":
        return "Our Knowledge-base provides helpful articles on how to handle common player issues during your career.";
      case "legal":
        return "Our Knowledge-base provides helpful articles on how to handle common player issues during your career.";
      case "league":
        return "Our Knowledge-base provides helpful articles on how to handle common player issues during your career.";
      default:
        return;
    }
  };

  const groupedArticles = useMemo(
    () =>
      Object.values(articles || []).filter((a) =>
        a.tags.some((t) => t?.toLowerCase() === tag?.toLowerCase())
      ),
    [articles]
  );

  return (
    <div className={classes.container}>
      {Object.values(groupedArticles || []).length > 0 ? (
        <div className={classes.subContainer}>
          <Header
            title={capitalizeFirstLetter(
              tag
                .replace(/contractrepository/gi, "Contract Repository")
                .replace(/strategicpartners/gi, "Strategic Partners")
                .replace(
                  /professionaldevelopment/gi,
                  "Professional Development"
                )
            )}
            description={getDescription(tag)}
          />
          {tag === "contractrepository" && (
            <div className={classes.legalDisclaimerCard}>
              <p className={classes.p}> {legalDisclaimer}</p>
            </div>
          )}
          <ArticlesContainer articles={groupedArticles} />
        </div>
      ) : (
        <div className={classes.subContainer}>
          <Header
            title={"No Articles Found"}
            description={"No articles are available for this section."}
          ></Header>
        </div>
      )}
    </div>
  );
};

export default ArticlesMain;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto auto",
  },
  subContainer: {
    marginBottom: "100px",
  },
  legalDisclaimerCard: {
    width: "100%",
    height: "fit-content",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px grey",
    padding: "18px",
    backgroundColor: "rgba(255, 0, 43, 0.2)",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  p: {
    fontSize: "14px",
    color: "black",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    textAlign: "justify",
  },
}));
