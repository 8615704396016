// Actions
const LOAD_ALL = "notifications/LOAD_ALL";
const LOAD_UNREAD = "notifications/LOAD_UNREAD";
const UPDATE = "notifications/UPDATE";
const REMOVE_ALL = "notifications/REMOVE_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.notifications.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNREAD:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.notifications.reduce((a, b) => ((a[b.id] = b), a), {}),
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.notification.id]: {
            ...state.data[action.notification.id],
            ...action.notification,
          },
        },
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const loadNotifications = (notifications) => ({
  type: LOAD_ALL,
  notifications,
});
export const loadUnreadNotifications = (notifications) => ({
  type: LOAD_UNREAD,
  notifications,
});
export const putNotification = (notification) => ({
  type: UPDATE,
  notification,
});
export const deleteNotifications = () => ({ type: REMOVE_ALL });
