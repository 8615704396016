import React, { useReducer, createContext } from "react";

// TYPES
const OPEN_MODAL = "OPEN_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";
const GET_CONTACT_INFO = "GET_CONTACT_INFO";

const initialState = {
  show: false,
  contactInfo: {},
};

const ModalReducer = (state, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, show: true };
    case CLOSE_MODAL:
      return { ...state, show: false };
    case GET_CONTACT_INFO:
      return { ...state, contactInfo: action.payload };
    default:
      return state;
  }
};

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [ModalState, dispatch] = useReducer(ModalReducer, initialState);

  const showModalHandler = () =>
    dispatch({
      type: ModalState.show ? CLOSE_MODAL : OPEN_MODAL,
    });

  const getContactInfo = (info) =>
    dispatch({ type: GET_CONTACT_INFO, payload: info });

  return (
    <ModalContext.Provider
      value={{ ModalState, showModalHandler, getContactInfo }}
    >
      {children}
    </ModalContext.Provider>
  );
};
