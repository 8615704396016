import React, { useMemo } from "react";
import Swiper from "react-id-swiper";

import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const useStyles = makeStyles((theme) => ({
  childContainer: {
    // margin: "auto",
    width: "auto",
    padding: "5px 10px 30px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const Carousel = ({ children, width }) => {
  const classes = useStyles();
  const params = isWidthUp("sm", width)
    ? {
        slidesPerView: isWidthUp("md", width) ? 3 : 2,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        style: { width: "auto" },
      }
    : {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        containerClass: classes.container,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      };

  return (
    <Swiper {...params} className={classes.childContainer}>
      {children}
    </Swiper>
  );
};

export default withWidth()(Carousel);
