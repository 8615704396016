import React from "react";

const ArrowIcon = ({ color }) => {
  const setColor = () => {
    if (color === "gold") {
      return "#A28C62";
    } else if (color === "white") {
      return "#FFFFFF";
    } else if (color === "lightGrey") {
      return "#9B9C9D";
    } else if (color === "grey") {
      return "#696A6D";
    } else if (color === "blue") {
      return "#3F6EDE";
    } else if (color === "red") {
      return "#FF002B";
    } else if (color === "slack") {
      return "#431847";
    }
  };

  return (
    <svg
      width="17px"
      height="15px"
      viewBox="0 0 17 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Path</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Dashboards-v1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dashboard---Desktop"
          transform="translate(-1172.000000, -659.000000)"
          fill={setColor()}
          fillRule="nonzero"
        >
          <g id="CBA-Section" transform="translate(0.052221, 655.000000)">
            <g id="View-all-CTA" transform="translate(931.947779, 4.000000)">
              <path
                d="M256.044407,5.97707379 L256.070943,5.98313222 L244.69532,5.98313222 L248.2714,2.24262878 C248.446514,2.0600104 248.542571,1.81262451 248.542571,1.55297752 C248.542571,1.29333052 248.446514,1.04767562 248.2714,0.864624488 L247.714961,0.283592216 C247.539986,0.100973831 247.306823,0 247.058181,0 C246.809401,0 246.5761,0.100252589 246.401124,0.282870975 L240.271036,6.68014015 C240.09537,6.86347977 239.999313,7.1076922 240,7.36748344 C239.999313,7.62871716 240.09537,7.87307383 240.271036,8.05612496 L246.401124,14.4539711 C246.5761,14.6364453 246.809262,14.7368421 247.058181,14.7368421 C247.306823,14.7368421 247.539986,14.636301 247.714961,14.4539711 L248.2714,13.8729389 C248.446514,13.690609 248.542571,13.4471178 248.542571,13.1874708 C248.542571,12.9279681 248.446514,12.697315 248.2714,12.5148408 L244.654962,8.75342139 L256.057122,8.75342139 C256.569472,8.75342139 257,8.29254798 257,7.75810792 L257,6.93632518 C257,6.40188512 256.556756,5.97707379 256.044407,5.97707379 Z"
                id="Path"
                transform="translate(248.500000, 7.368421) scale(-1, 1) translate(-248.500000, -7.368421) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowIcon;
