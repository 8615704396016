import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  h1: {
    fontSize: "36px",
    ...setStyles.sofiaProBold,
  },
  nav: {
    display: "flex",
    listStyle: "none",
    justifyContent: "space-between",
    width: "50%",
    alignItems: "center",
    ...setStyles.sofiaProSemiBold,
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: 15,
    },
  },
  active: {
    color: "red",
    marginTop: "3px",
    borderBottom: "3px solid red",
  },
}));

const EventsHeader = ({ index, setIndex }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h1 className={classes.h1}>EVENTS CALENDAR</h1>
      <ul className={classes.nav}>
        <li
          style={{ cursor: "pointer" }}
          className={index === 0 && classes.active}
          onClick={() => setIndex(0)}
        >
          All
        </li>
        <li
          style={{ cursor: "pointer" }}
          className={index === 1 && classes.active}
          onClick={() => setIndex(1)}
        >
          Featured
        </li>
        <li
          style={{ cursor: "pointer" }}
          className={index === 2 && classes.active}
          onClick={() => setIndex(2)}
        >
          Other
        </li>
      </ul>
    </div>
  );
};

export default EventsHeader;
