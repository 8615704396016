import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

import Chat from "../../images/Icons/Chat";
import Contract from "../../images/Icons/Contract";
import LeagueContact from "../../images/Icons/LeagueContact";
import Legal from "../../images/Icons/Legal";
import Medical from "../../images/Icons/Medical";
import WorkIcon from "@material-ui/icons/Work";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DraftsIcon from "@material-ui/icons/Drafts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import FolderIcon from "@material-ui/icons/Folder";

import ColoredButton from "../Dashboard/SearchBar/ColoredButton";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    maxWidth: "800px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  h1: {
    color: "#05070B",
    ...setStyles.sofiaProBold,
    fontSize: "36px",
    paddingBottom: "20px",
    width: "100%",
  },
  subheading: {
    color: "#404040",
    opacity: 0.75,
    fontSize: "30px",
    letterSpacing: "0.18px",
    ...setStyles.sofiaProLight,
    paddingBottom: "30px",
    lineHeight: "30px",
    width: "100%",
  },
  searchBarContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridGap: 10,
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "100%",
      maxWidth: "300px",
      height: "auto",
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const data = [
    {
      getElementById: "LegalHelp",
      colors: ["#9F9F9F", "#7A7A7A"],
      color: "lightgrey",
      text: "Legal Help",
      icon: <Legal color="white" />,
    },
    {
      getElementById: "Career",
      colors: ["#4B7BEC", "#3867D6"],
      color: "blue",
      text: "Career",
      icon: <WorkIcon fontSize="large" style={{ color: "white" }} />,
    },
    {
      getElementById: "StrategicPartners",
      colors: ["#E48C00", "#B76C00"],
      color: "burntOrange",
      text: "Strategic Partners",
      icon: (
        <FontAwesomeIcon
          icon={faHandshake}
          style={{ color: "white", fontSize: "32px" }}
        />
      ),
    },
    {
      getElementById: "ContractRepository",
      colors: ["#3AA6FF", "#1B95E0"],
      color: "navyBlue",
      text: "Contract Repository",
      icon: <FolderIcon fontSize="large" style={{ color: "white" }} />,
    },

    {
      getElementById: "LeagueHelp",
      colors: ["#3DFBEC", "#0AC9BA"],
      color: "grey",
      text: "League Help",
      icon: <LeagueContact />,
    },
    {
      getElementById: "MedicalHelp",
      colors: ["#FF4D4D", "#FF3838"],
      color: "red",
      text: "Medical",
      icon: <Medical />,
    },
    {
      getElementById: "Benefits",
      colors: ["#32CF52", "#32CD32"],
      color: "lime",
      text: "Benefits",
      icon: <LocalOfferIcon fontSize="large" style={{ color: "white" }} />,
    },
    {
      getElementById: "Pre-Draft",
      colors: ["#F1CB00", "#F1CB00"],
      color: "yellow",
      text: "Pre-Draft",
      icon: <DraftsIcon fontSize="large" style={{ color: "white" }} />,
    },

    {
      getElementById: "Off-Season",
      colors: ["#FF89D3", "#FF5EBF"],
      color: "pink",
      text: "Off-Season",
      icon: <CalendarTodayIcon fontSize="large" style={{ color: "white" }} />,
    },
    {
      getElementById: "ContractHelp",
      colors: ["#FFAF40", "#FF9F1A"],
      color: "orange",
      text: "Contract Help",
      icon: <Contract />,
    },
  ];

  return (
    <div className={classes.container}>
      <h1 className={classes.h1}>Player Education</h1>
      <p className={classes.subheading}>
        Our Knowledge-base provides helpful articles on how to handle common
        player issues during your career.
      </p>

      <div className={classes.searchBarContainer}>
        {data.map((item) => (
          <div
            onClick={() =>
              document.getElementById(item.getElementById).scrollIntoView({
                block: "center",
              })
            }
          >
            <ColoredButton
              colors={item.colors}
              color={item.color}
              text={item.text}
            >
              {item.icon}
            </ColoredButton>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Header;
