export const API_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.stratic.app/api/v1/"
    : process.env.REACT_APP_ENV === "development"
    ? "https://samapi.helm.design/api/v1/"
    : "http://localhost:4899/api/v1/";

export const SPOTTRAC_KEY = "0ad9c4dc803d775377e24edd69f1628d";
export const SPOTTRAC_API_URL = "https://www.spotrac.com/api/stratic/";
export const SPOTTRAC_PLAYER = "25102";

export const notificationURLTypes = Object.freeze({
  Global: "dashboard",
  Events: "event",
  Educations: "article",
  Contacts: "leagueaccess",
});

export const notificationTypes = Object.freeze({
  GLOBAL: "Global",
  EVENT: "Events",
  EDUCATION: "Educations",
  CONTACT: "Contacts",
  ARCHIVE: "Archived",
});

export const contactTypes = Object.freeze({
  PROFESSIONAL_SERVICES: "professional_services",
  LEAGUE_CONTACT: "league_contact",
  MARKETING_CONTACT: "marketing_contact",
});

export const leagueTypes = Object.freeze({
  ALL: "All",
  NFL: "NFL",
  UFL: "UFL",
  CFL: "CFL",
  NFLPA: "NFLPA",
});

export const defaultServices = Object.freeze({
  LAWYER: "Lawyer",
  DOCTOR: "Doctor",
});

export const lawyerRoles = Object.freeze({
  BRANDING_TRADEMARKS: "Branding & Trademarks",
  BUSINESS_FORMATION: "Business Formation",
  DIVORCE: "Divorce",
  PERSONAL_INJURY: "Personal Injury",
  TRUSTS_ESTATES: "Trusts & Estates",
  WORKERS_COMP: "Workers Comp",
  TAXES_IRS: "Taxes/IRS",
});

export const doctorRoles = Object.freeze({
  ANKLE: "Ankle",
  ELBOW: "Elbow",
  FOOT: "Foot",
  HAND: "Hand",
  HEAD: "Head",
  HIP: "Hip",
  KNEE: "Knee",
  NECK_SPINE: "Neck/Spine",
  SHOULDER: "Shoulder",
  WRIST: "Wrist",
});

export const documentTypes = Object.freeze({
  CBA: "CBA",
  DRUG_POLICY: "Drug Policy",
  AGENT_REGULATION: "Agent Regulations",
});

export const documentLinkTypes = Object.freeze({
  CBA: "CBA",
  DRUG_POLICY: "drug_policy",
  AGENT_REGULATION: "agent_regulations",
});
