import React from "react";

import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";

import goldImage from "../../../images/goldImage.png";
import tealImage from "../../../images/tealImage.png";

const BoxB = ({ title, values, color }) => {
  const classes = useStyles();

  return (
    <Grid item sm={12} md={6} className={classes.container}>
      <div />
      <Grid container direction="column" className={classes.innerContainer}>
        <h5 className={classes.h5}>{title}</h5>
        <div className={classes.divider} />
        <Grid container direction="column">
          {values.map((val, i) => (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.row}
              key={i}
            >
              <h6 style={{ padding: 0 }}>{val[0]}</h6>
              <h6 className={color == "blue" ? classes.blue : classes.green}>
                {val[1]}
              </h6>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BoxB;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "220px",
    width: "100%",
    height: "150px",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px grey",
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "480px",
      margin: "auto auto 20px",
    },
  },
  image1: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundImage: `url(${goldImage})`,
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "220px",
    height: "150px",
  },
  image2: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundImage: `url(${tealImage})`,
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "220px",
    height: "150px",
  },
  innerContainer: {
    width: "100%",
    height: "100%",
    margin: "auto",
    position: "relative",
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid grey",
    opacity: 0.15,
  },
  row: {
    padding: "1px 7px",
  },
  h5: {
    padding: "8px",
    fontSize: "14px",
    ...setStyles.sofiaProBlack,
  },
  blue: {
    color: "#3F6EDE",
    fontSize: "16px",
    ...setStyles.sofiaProSemiBold,
  },
  green: {
    color: "#1DCEC0",
    fontSize: "16px",
    ...setStyles.sofiaProSemiBold,
  },
}));
