import React, { useMemo, useState } from "react";

import Header from "../Components/LeagueAccess/Header";
import Filter from "../Components/LeagueAccess/Filter";
import ContactsContainer from "../Components/LeagueAccess/ContactsContainer";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { contactTypes, leagueTypes } from "../lib/constants";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchContacts } from "../api/contacts";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { reduceContacts } from "../lib/utils";

const LeagueAccess = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [leagueFilter, setLeagueFilter] = useState("All");
  const [serviceFilter, setServiceFilter] = useState("All");

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash; // Get the hash value from the URL (e.g., #objectId)

    if (hash) {
      const element = document.getElementById(hash.substring(1)); // Remove the "#" symbol from the hash

      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Scroll to the element
      }
    }
  }, [location]);

  const { contacts } = useReduxQuery("Contacts", fetchContacts, {
    selector: (state) =>
      Object.values(state.contacts.data || []).filter(
        (c) => c?.type === contactTypes.LEAGUE_CONTACT
      ),
  });

  const leagues = useMemo(() => {
    const tags = [
      ...new Set(Object.values(contacts || []).map((c) => c.league)),
    ];
    return tags ? ["All", ...tags] : null;
  }, [contacts]);

  const services = useMemo(() => {
    const tags = [
      ...new Set(
        Object.values(contacts || [])
          .map((c) => c.services)
          .flat()
      ),
    ];
    return tags ? ["All", ...tags] : null;
  }, [contacts]);

  const cleanSearch = (val) => val?.toLowerCase()?.trim()?.replace(/\s/g, "");
  const bySearch = (c, val) => {
    if (!val) return true;
    const value = cleanSearch(val);
    const name = cleanSearch(`${c.firstName}${c.lastName}`);
    const fullTeam = cleanSearch(`${c.team?.city}${c.team?.name}`);
    const team = cleanSearch(c.team?.name);
    const company = cleanSearch(c.companyName) || "";
    const city = cleanSearch(c.team?.city);
    const isMatch =
      name?.includes(value) ||
      team?.includes(value) ||
      company?.includes(value) ||
      fullTeam?.includes(value) ||
      city?.includes(value);

    return isMatch;
  };

  const byLeague = (c, league) => {
    if (league === "NFLPA") return true;
    if (league === "All") return true;
    return c?.league === league;
  };

  const byServices = (c, service) => {
    if (service === "All") return true;
    return c?.services?.includes(service);
  };

  const byTeam = (c, league) => {
    if (league === c?.team?.name) return true;
    return false;
  };

  const filteredContacts = useMemo(
    () =>
      Object.values(contacts || []).filter((c) => {
        if (leagueFilter === "NFLPA")
          return (
            bySearch(c, search) &&
            byLeague(c, leagueFilter) &&
            byServices(c, serviceFilter) &&
            byTeam(c, leagueFilter)
          );
        return (
          bySearch(c, search) &&
          byLeague(c, leagueFilter) &&
          byServices(c, serviceFilter)
        );
      }),
    [contacts, search, serviceFilter, leagueFilter]
  );

  const groupedContacts = useMemo(() => {
    return reduceContacts(filteredContacts);
  }, [filteredContacts]);

  const onChangeText = (val) => setSearch(val);
  const onSelectLeague = (filter) => setLeagueFilter(filter);
  const onSelectService = (filter) => setServiceFilter(filter);

  return (
    <div className={classes.container}>
      <Header />
      <Filter
        leagueFilter={leagueFilter}
        leagueFilters={Object.values(leagueTypes || [])}
        serviceFilter={serviceFilter}
        serviceFilters={services}
        onSelectLeague={onSelectLeague}
        onSelectService={onSelectService}
        search={search}
        onSearch={onChangeText}
        nbContacts={(filteredContacts || []).length}
      />
      <ContactsContainer contacts={groupedContacts} />
    </div>
  );
};

export default LeagueAccess;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto auto",
  },
}));
