import { loadContacts, loadContact } from "../redux/contacts";
import { axiosHandler } from "../lib/utils";

export const fetchContacts = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({ url: `contacts`, state: getState() });
    await dispatch(loadContacts(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchContact = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `contacts/${id}`,
      state: getState(),
    });
    await dispatch(loadContact(json.data));
  } catch (error) {
    console.log(error);
    throw error;
  }
};
