import React, { useState } from "react";

import { Box, Grid, LinearProgress, Modal } from "@material-ui/core";

import { makeStyles, withStyles, lighten } from "@material-ui/core/styles";

import goldImage from "../../images/goldImage.png";
import tealImage from "../../images/tealImage.png";
import { HelpOutline } from "@material-ui/icons";

const StatsB = ({ numSeas, titleA, titleB, perc, color }) => {
  const classes = useStyles();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [openCredited, setCreditedOpen] = useState(false);
  const handleCreditedOpen = () => setCreditedOpen(true);
  const handleCreditedClose = () => setCreditedOpen(false);

  const [openAccrued, setAccruedOpen] = useState(false);
  const handleAccruedOpen = () => setAccruedOpen(true);
  const handleAccruedClose = () => setAccruedOpen(false);

  return (
    <>
      <Grid item sm={12} md={6} className={classes.contractContainer}>
        <div className={color == "gold" ? classes.image1 : classes.image2} />
        <Grid container direction="column" className={classes.innerContainer}>
          <h1 className={color == "gold" ? classes.h1a : classes.h1b}>
            {numSeas}
          </h1>
          <div className={classes.innerBox3}>
            <h4>{titleA}</h4>
            <p
              className={classes.icon}
              onClick={
                titleA === "Accrued Seasons"
                  ? handleAccruedOpen
                  : handleCreditedOpen
              }
            >
              <HelpOutline fontSize="small" />
            </p>
          </div>
          <div className={classes.divider} />
          <div className={classes.flexRow}>
            <h5>{titleB}</h5>
            <h5>{perc}%</h5>
          </div>
          {color == "gold" ? (
            <BorderLinearProgressA
              variant="determinate"
              // color="secondary"
              value={perc}
            />
          ) : (
            <BorderLinearProgressB
              variant="determinate"
              // color="secondary"
              value={perc}
            />
          )}
        </Grid>
      </Grid>
      <Modal
        open={openCredited}
        onClose={handleCreditedClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p>
              A credited season is earned when a player is on the
              active/inactive list or on injured reserve for 3 or more regular
              season games. Earning credited seasons is necessary in order for a
              player to become eligible for certain benefits. While various
              benefits require a different number of credited seasons, the
              majority of benefits require 3 credited seasons.
            </p>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openAccrued}
        onClose={handleAccruedClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p>
              An accrued season is earned when a player is on the
              active/inactive list or on injured reserve for 6 or more regular
              season games. Earning accrued seasons is necessary in order for a
              player to become an unrestricted free agent. If a player has 4 or
              more accrued seasons when their contract expires, they are
              considered an unrestricted free agent. If a player has 3 accrued
              seasons when their contract expires,they are considered a
              restricted free agent. Lastly, if a player has 2 or less accrued
              seasons when their contract expires, they are considered an
              exclusive rights player.
            </p>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default StatsB;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "220px",
    width: "100%",
    height: "150px",
    // backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px grey",
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      marginBottom: "20px",
    },
  },
  contractContainer: {
    maxWidth: "220px",
    width: "100%",
    height: "150px",
    // backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px grey",
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "480px",
      margin: "auto auto 20px",
    },
  },
  icon: {
    padding: "0px 10px",
    fontSize: "12px",
  },
  image1: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundImage: `url(${goldImage})`,
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "220px",
    height: "150px",
  },
  image2: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundImage: `url(${tealImage})`,
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "220px",
    height: "150px",
  },
  innerContainer: {
    width: "90%",
    height: "100%",
    margin: "auto",
    position: "relative",
  },
  innerBox3: {
    textAlign: "center",
    zIndex: 2,
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  h1a: {
    color: "#E38606",
  },
  h1b: {
    color: "#1DCEC0",
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid grey",
    opacity: 0.15,
    margin: "10px 0px",
  },
  flexRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: "10px",
  },
}));

const BorderLinearProgressA = withStyles({
  root: {
    height: 13,
    borderRadius: 20,
    backgroundColor: lighten("#F4B419", 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#F4B419",
  },
})(LinearProgress);

const BorderLinearProgressB = withStyles({
  root: {
    height: 13,
    borderRadius: 20,
    backgroundColor: lighten("#1DCEC0", 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#1DCEC0",
  },
})(LinearProgress);
