// Actions
const CREATE = "token/CREATE";
const REMOVE = "token/REMOVE";

// Reducer
const initialState = {
  accessToken: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE:
      return { ...state, accessToken: action.accessToken };
    case REMOVE:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function createToken(accessToken) {
  return { type: CREATE, accessToken };
}
export function removeToken() {
  return { type: REMOVE };
}
