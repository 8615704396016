import ExpandableSection from "../Components/Standalones/ExpandableSection";
import Header from "../Components/Parent/Header";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import AllUsers from "../Components/Standalones/AllUsers";

const Parent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Header />
      <ExpandableSection placeholder="My Players">
        <AllUsers selected={true} fullWidth={true} />
      </ExpandableSection>
      <ExpandableSection placeholder="All Players">
        <AllUsers fullWidth={true} />
      </ExpandableSection>
    </div>
  );
};

export default Parent;

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto auto",
  },
}));
