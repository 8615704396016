import React, { useMemo } from "react";
import Header from "../Components/Contacts/Header";
import Filter from "../Components/Contacts/Filter";
import ContactsContainer from "../Components/Contacts/ContactsContainer";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../setStyles";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchContacts } from "../api/contacts";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { reduceContacts, reduceMarketingContacts } from "../lib/utils";

const Contacts = ({ match, type, titleText }) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState("");
  const [filter, setFilter] = React.useState(match.params.filter || "All");
  const [role, setRole] = React.useState(match.params.role || "All");

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash; // Get the hash value from the URL (e.g., #objectId)

    if (hash) {
      const element = document.getElementById(hash.substring(1)); // Remove the "#" symbol from the hash

      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Scroll to the element
      }
    }
  }, [location]);

  const { contacts } = useReduxQuery("Contacts", fetchContacts, {
    selector: (state) =>
      Object.values(state.contacts.data || []).filter((c) => c?.type === type),
  });

  const services = useMemo(() => {
    const tags = [
      ...new Set(
        Object.values(contacts || [])
          .map((c) => c.services)
          .flat()
      ),
    ];
    return tags ? ["All", ...tags] : null;
  }, [contacts]);

  const cleanSearch = (val) => val?.toLowerCase()?.trim()?.replace(/\s/g, "");
  const bySearch = (c, val) => {
    if (!val) return true;
    const value = cleanSearch(val);
    const name = cleanSearch(`${c.firstName}${c.lastName}`);
    const fullTeam = cleanSearch(`${c.team?.city}${c.team?.name}`);
    const team = cleanSearch(c.team?.name);
    const company = cleanSearch(c.companyName) || "";
    const city = cleanSearch(c.city);
    const isMatch =
      name?.includes(value) ||
      team?.includes(value) ||
      company?.includes(value) ||
      fullTeam?.includes(value) ||
      city?.includes(value);
    return isMatch;
  };

  const byServices = (c, service) => {
    if (service === "All") return true;
    return c?.services?.includes(service);
  };

  const byRole = (c, role) => {
    if (role === "All") return true;
    return c?.role === role;
  };

  const filteredContacts = useMemo(
    () =>
      Object.values(contacts || []).filter(
        (c) => bySearch(c, search) && byServices(c, filter) && byRole(c, role)
      ),
    [contacts, search, filter, role]
  );

  const groupedContacts = useMemo(() => {
    if (location?.pathname?.includes("marketingcontacts"))
      return reduceMarketingContacts(filteredContacts);
    return reduceContacts(filteredContacts);
  }, [filteredContacts, location?.pathname]);

  const onChangeText = (val) => setSearch(val);
  const onSelectFilter = (filter) => {
    //If filter is not an object, set filter
    if (typeof filter !== "object") {
      setRole("All");
      setFilter(filter);
    } else {
      setRole(filter.role);
      setFilter(filter.filter);
    }
  };

  return (
    <div className={classes.container}>
      <Header titleText={titleText} />
      <Filter
        filters={services}
        filter={filter}
        onSelect={onSelectFilter}
        search={search}
        onSearch={onChangeText}
        nbContacts={(filteredContacts || []).length}
      />
      <ContactsContainer contacts={groupedContacts} />
    </div>
  );
};

export default Contacts;

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: setStyles.maxWidth,
    margin: "110px auto auto",
  },
}));
