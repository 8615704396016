import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";

const useStyles = makeStyles((theme) => ({
  iconLinkContainer: {
    borderTop: "1px solid rgba(151, 151, 151, 0.3)",
    margin: "0px 20px",
  },
  iconLinkA: {
    display: "flex",
    color: "black",
    textDecoration: "none",
    textAlign: "center",
    alignItems: "center",
    padding: "14px 0px",
    backgroundColor: "transparent",
    border: "none",
    ...setStyles.sofiaProRegular,
    fontWeight: 400,
    fontSize: "16px",
    cursor: "pointer",
  },
  iconLinkP: {
    ...setStyles.sofiaProRegular,
    color: "#05070b",
    lineHeight: "25px",
  },
  img: {
    width: "25px",
    marginRight: "10px",
  },
}));

const IconButton1 = ({ text, image, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.iconLinkContainer}>
      <button onClick={onClick} className={classes.iconLinkA}>
        <img src={image} alt="" className={classes.img} />
        <p>{text}</p>
      </button>
    </div>
  );
};

export default IconButton1;
