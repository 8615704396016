import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";

const useStyles = makeStyles((theme) => ({
  iconLinkContainer: {
    borderTop: "1px solid rgba(151, 151, 151, 0.3)",
    margin: "0px 20px",
  },
  iconLinkA: {
    display: "flex",
    color: "black",
    textDecoration: "none",
    textAlign: "center",
    alignItems: "center",
    padding: "14px 0px",
  },
  iconLinkP: {
    fontSize: "20px",
    ...setStyles.sofiaProSemiBold,
    color: "#05070b",
    lineHeight: "25px",
  },
  img: {
    width: "25px",
    marginRight: "10px",
  },
}));

const IconLink2 = ({ text, image, url }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconLinkContainer}>
      <a href={url} className={classes.iconLinkA}>
        <img src={image} alt="" className={classes.img} />
        <p>{text}</p>
      </a>
    </div>
  );
};

export default IconLink2;
