// Actions
const LOAD_CHILDREN = "user/LOAD_CHILDREN";
const ADD_CHILD = "user/ADD_CHILD";
const REMOVE_CHILD = "user/REMOVE_CHILD";

// Reducer
const initialState = {
  data: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CHILDREN:
      const childrenObject = action.children.reduce((acc, child) => {
        acc[child.playerId] = child;
        return acc;
      }, {});

      return { ...state, data: childrenObject };
    case ADD_CHILD:
      return {
        ...state,
        data: { ...state.data, [action.child.playerId]: action.child },
      };
    case REMOVE_CHILD:
      const newData = { ...state.data };
      delete newData[action.child.playerId];
      return { ...state, data: newData };
    default:
      return state;
  }
}

// Action Creators
export function loadChildren(children) {
  return { type: LOAD_CHILDREN, children };
}
export function addChild(child) {
  return { type: ADD_CHILD, child };
}
export function removeChild(child) {
  return { type: REMOVE_CHILD, child };
}
