import React, { useMemo } from "react";

import { Grid } from "@material-ui/core";

import ColoredButton from "./ColoredButton";

import Chat from "../../../images/Icons/Chat";
import Contract from "../../../images/Icons/Contract";
import LeagueContact from "../../../images/Icons/LeagueContact";
import Legal from "../../../images/Icons/Legal";
import Medical from "../../../images/Icons/Medical";
import Services from "../../../images/Icons/Services";
import WorkIcon from "@material-ui/icons/Work";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DraftsIcon from "@material-ui/icons/Drafts";
import FolderIcon from "@material-ui/icons/Folder";
import MarketingIcon from "../../../images/Icons/Marketing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faHandshake } from "@fortawesome/free-solid-svg-icons";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import { makeStyles } from "@material-ui/core/styles";
import { useReduxQuery } from "../../../hooks/useReduxQuery";
import { fetchSettings } from "../../../api/settings";
import { userTypes } from "../../../utils/constants";

const SearchBarContainer = ({ user }) => {
  const classes = useStyles();
  const { settings } = useReduxQuery("Settings", fetchSettings, {
    selector: (state) => state.settings.data,
  });

  const phones = useMemo(
    () =>
      Object.values(settings || [])
        .filter((s) => s.type?.startsWith("phone"))
        ?.map((p) => p.value) || [],
    [settings]
  );

  const buttonData = [
    {
      color: "#9F9F9F",
      colors: ["#9F9F9F", "#7A7A7A"],
      text: "League Contacts",
      url: "/leagueaccess",
      icon: <LeagueContact />,
    },
    {
      color: "#4B7BEC",
      colors: ["#4B7BEC", "#3867D6"],
      text: "Professional Services",
      url: "/professionalservices",
      icon: <WorkIcon fontSize="large" style={{ color: "white" }} />,
    },
    {
      color: "forestGreen",
      colors: ["#00E090", "#00A560"],
      text: "Marketing Contacts",
      url: "/marketingcontacts",
      icon: (
        <FontAwesomeIcon
          icon={faAddressBook}
          style={{ color: "white", fontSize: "32px" }}
        />
      ),
    },
    {
      color: "#E48C00",
      colors: ["#E48C00", "#B76C00"],
      text: "Strategic Partners",
      url: "/articles/strategicpartners",
      icon: (
        <FontAwesomeIcon
          icon={faHandshake}
          style={{ color: "white", fontSize: "32px" }}
        />
      ),
    },
    {
      color: "navyBlue",
      colors: ["#3AA6FF", "#1B95E0"],
      text: "Contract Repository",
      url: "/articles/contractrepository",
      icon: <FolderIcon fontSize="large" style={{ color: "white" }} />,
    },

    {
      color: "teal",
      colors: ["#3DFBEC", "#0AC9BA"],
      text: "Professional Development",
      url: "/professionaldevelopment",
      icon: <Legal color="white" />,
    },
    {
      color: "red",
      colors: ["#FF4D4D", "#FF3838"],
      text: "Injuries",
      url: "/articles/medical",
      icon: <Medical />,
    },
    {
      color: "lime",
      colors: ["#32CF52", "#32CD32"],
      text: "Benefits",
      url: "/articles/benefits",
      icon: <LocalOfferIcon fontSize="large" style={{ color: "white" }} />,
    },

    {
      color: "yellow",
      colors: ["#F1CB00", "#F1CB00"],
      text: "Pre-Draft",
      url: "/articles/pre-draft",
      icon: <DraftsIcon fontSize="large" style={{ color: "white" }} />,
    },

    {
      color: "pink",
      colors: ["#FF89D3", "#FF5EBF"],
      text: "Off-Season",
      url: "/articles/off-season",
      icon: <CalendarTodayIcon fontSize="large" style={{ color: "white" }} />,
    },
    {
      color: "orange",
      colors: ["#FFAF40", "#FF9F1A"],
      text: "Contract Help",
      url: "/articles/contract",
      icon: <Contract />,
    },

    {
      color: "purple",
      colors: ["#7D5FFF", "#7158E2"],
      text: "Contact Stratic",
      url: "sms:/open?addresses=" + phones.join(","),
      icon: <Chat />,
    },
  ];

  if (
    user?.userType === userTypes.PARENT ||
    user?.userType === userTypes.SPOUSE
  ) {
    buttonData.splice(0, 1);
  }

  const groupedButtonData = useMemo(() => {
    const pairs = [];
    for (let i = 0; i < buttonData.length; i += 2) {
      pairs.push(buttonData.slice(i, i + 2));
    }
    return pairs;
  }, []);

  return (
    <Grid item sm={12} md={6} className={classes.container}>
      <div className={classes.searchBarContainer}>
        <ColoredButton color="main" text="What can we help you with today?" />
        {groupedButtonData.map((pair, index) => (
          <div className={classes.searchBarRow} key={index}>
            {pair.map((button, innerIndex) => (
              <ColoredButton
                key={innerIndex}
                color={button.color}
                colors={button.colors}
                text={button.text}
                url={button.url}
              >
                {button.icon}
              </ColoredButton>
            ))}
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default SearchBarContainer;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto auto auto 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  searchBarContainer: {
    width: "380px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "100%",
      maxWidth: "300px",
      height: "auto",
    },
  },
  searchBarRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "25px",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      // flexDirection: "column",
    },
  },
  searchBarRowLast: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "25px",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      // flexDirection: "column",
    },
  },
}));
