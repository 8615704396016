import React from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";
import numberFormatter from "../../../utils/numberFormatter";
import { useReduxQuery } from "../../../hooks/useReduxQuery";
import { fetchUser } from "../../../api/user";

const headers = [
  "YEAR",
  "TEAM",
  "AGE",
  "BASE SALARY",
  "SIGNING BONUS",
  "CASH HIT",
  "DEAD CAP",
  "YEARLY CASH",
];

const CreateHeader = ({ columns }) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {columns.map((col, i) =>
          i === 0 ? (
            <TableCell align="left" key={col} className={classes.header}>
              {col.toUpperCase()}
            </TableCell>
          ) : (
            <TableCell align="center" key={col} className={classes.header}>
              {col.toUpperCase()}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

const CreateRows = ({ rows }) =>
  rows.map((row) => (
    <TableRow key={row.year}>
      <TableCell align="left">{row.year}</TableCell>
      <TableCell align="center">{row.team}</TableCell>
      <TableCell align="center">{row.age}</TableCell>
      <TableCell align="center">${numberFormatter(row.base)}</TableCell>
      <TableCell align="center">${numberFormatter(row.signing)}</TableCell>
      <TableCell align="center">${numberFormatter(row.total)}</TableCell>
      <TableCell align="center">${numberFormatter(row.roster)}</TableCell>
      <TableCell align="center">${numberFormatter(row.workout)}</TableCell>
    </TableRow>
  ));

const EarningsTable = () => {
  const classes = useStyles();
  const { user } = useReduxQuery("User", fetchUser);

  const mapRows = () =>
    (user?.contract?.breakdowns || []).map((b, i) => ({
      year: b.year,
      team: b?.team?.name || "-",
      age: b.age > 0 ? b.age : "-",
      base: b.baseSalary,
      signing: b.signingBonus,
      total: b.capHit,
      roster: b.deadCap,
      workout: b.yearlyCash,
    }));

  return (
    <div className={classes.container}>
      <TableContainer component={Paper} elevation={4}>
        <Table className={classes.table} aria-label="simple table">
          <CreateHeader columns={headers} />
          <TableBody>
            <CreateRows rows={mapRows()} />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EarningsTable;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "32px auto",
  },
  table: {
    minWidth: 650,
  },
  header: {
    color: "#A28C62",
    ...setStyles.sofiaProBold,
    fontSize: "11px",
    textTransform: "uppercase",
  },
  cell: {
    color: "#05070B",
    fontSize: "14px",
    ...setStyles.sofiaProRegular,
  },
  dash: {
    color: "#05070B",
    fontSize: "11px",
    ...setStyles.sofiaProRegular,
    opacity: 0.5,
  },
}));
