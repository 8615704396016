import React, { useMemo, useState } from "react";

import EventsHeader from "./EventsHeader";
import EventsCards from "./EventsCards";
import { useReduxQuery } from "../../hooks/useReduxQuery";
import { fetchEvents } from "../../api/events";
import { dateFormat } from "../../utils/utils";

const EventsContainer = () => {
  const [index, setIndex] = useState(0);
  const { events } = useReduxQuery("Events", fetchEvents);
  const filters = ["All", "Featured", "Other"];

  const filteredEvents = useMemo(
    () =>
      Object.values(events || []).filter((e) =>
        index === 0
          ? true
          : index === 1
          ? e.featured
          : index === 2 && !e.featured
          ? true
          : e?.tags?.includes(filters[index]?.toLowerCase())
      ),
    [index]
  );


  const filterBySelection = (index) => {
    setIndex(index);
  };

  return (
    <>
      <EventsHeader index={index} setIndex={filterBySelection} />
      <EventsCards
        events={(filteredEvents || [])
          .filter((e) => dateFormat(e?.endDate).unix() > dateFormat().unix())
          .sort(
            (a, b) =>
              dateFormat(a?.startDate).unix() - dateFormat(b?.startDate).unix()
          )}
      />
    </>
  );
};

export default EventsContainer;
