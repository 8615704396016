import React, { useMemo, useEffect } from "react";

import { Grid } from "@material-ui/core";

import IconLink from "./IconLink";
import MoreButton from "./MoreButton";
import ExpandedMenu from "./ExpandedMenu";

import HomeIcon from "../../../images/Icons/Home";
import CareerIcon from "../../../images/Icons/Career";
import CalendarIcon from "../../../images/Icons/Calendar";
import KnowledgeIcon from "../../../images/Icons/Knowledge";
import NotificationsIcon from "../../../images/Icons/Notifications";
import ParentIcon from "../../../images/Icons/Parent";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";
import { useReduxQuery } from "../../../hooks/useReduxQuery";
import { fetchNotifications } from "../../../api/notifications";
import Badge from "../../Badge";
import { useLocation } from "react-router-dom";
import { fetchUser } from "../../../api/user";
import { userTypes } from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  mainMenuContainer: {
    width: "437px",
    padding: "24px 0px",
    ...setStyles.setFlexRow,
    margin: "auto 0px auto auto",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      position: "static",
      margin: "auto",
      maxWidth: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "300px",
      padding: "24px 0px",
    },
  },
  badgeContainer: {
    position: "relative",
  },
}));

const MainMenu = ({ openMenu, showMore, closeMenu, pathName }) => {
  const classes = useStyles();
  const { notifications } = useReduxQuery("Notifications", fetchNotifications);
  const nbUnreadNotification = useMemo(
    () =>
      Object.values(notifications || []).filter((n) => !n.read)?.length || 0,
    [notifications]
  );

  const { user } = useReduxQuery("User", fetchUser);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/login") {
      window.danteEmbed =
        "https://chat.dante-ai.com/embed?kb_id=e5f0311b-ee71-4d01-adb4-7f284686acc2&token=a8f0bbc4-61d9-46b6-82e2-e930ed33f712&modeltype=gpt-4&mode=false&bubble=true&image=https://dante-chatbot-pictures.s3.amazonaws.com/e5f0311b-ee71-4d01-adb4-7f284686acc2/stratic-ai-high-resolution-logo-black-on-transparent-background.png&bubbleopen=false";
      const script = document.createElement("script");
      script.src = "https://chat.dante-ai.com/bubble-embed.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [location.pathname]);

  return (
    <Grid item md={6} sm={12} className={classes.mainMenuContainer}>
      <IconLink text="Home" url="/" active={pathName === "/"}>
        <HomeIcon active={pathName === "/"} />
      </IconLink>
      {user?.userType !== userTypes.PARENT &&
        user?.userType !== userTypes.SPOUSE && (
          <IconLink
            text="Career"
            url="/contract"
            active={pathName === "/contract"}
          >
            <CareerIcon active={pathName === "/contract"} />
          </IconLink>
        )}
      {user?.userType === userTypes.PARENT && (
        <IconLink text="Parent" url="/parent" active={pathName === "/parent"}>
          <ParentIcon active={pathName === "/parent"} />
        </IconLink>
      )}
      {user?.userType === userTypes.SPOUSE && (
        <IconLink text="Spouse" url="/spouse" active={pathName === "/spouse"}>
          <ParentIcon active={pathName === "/spouse"} />
        </IconLink>
      )}
      <IconLink text="Events" url="/events" active={pathName === "/events"}>
        <CalendarIcon active={pathName === "/events"} />
      </IconLink>
      <IconLink
        text="Education"
        url="/playerEducation"
        active={pathName === "/playerEducation"}
      >
        <KnowledgeIcon active={pathName === "/playerEducation"} />
      </IconLink>
      <div className={classes.badgeContainer}>
        {nbUnreadNotification > 0 && <Badge nb={nbUnreadNotification} />}
        <IconLink text="Notifications" url="/notifications">
          <NotificationsIcon />
        </IconLink>
      </div>
      <MoreButton moreHandler={openMenu} />
      <ExpandedMenu showMore={showMore} closeMenu={closeMenu} user={user} />
    </Grid>
  );
};

export default MainMenu;
