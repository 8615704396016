import React from "react";

const KownledgeIcon = ({ active }) => {
  return (
    <svg
      width="19px"
      height="21px"
      viewBox="0 0 19 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>education</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Dashboards-v1"
        stroke="none"
        strokeWidth="1"
        fill={active ? "#FF002B" : "#FFFFFF"}
        fillRule="evenodd"
      >
        <g
          id="Dashboard---Nav-Tray"
          transform="translate(-488.000000, -24.000000)"
        >
          <g id="Header" transform="translate(-104.000000, -23.000000)">
            <g id="Primary-Nav" transform="translate(356.000000, 47.000000)">
              <g id="Education" transform="translate(218.000000, 0.000000)">
                <path
                  d="M20.3468301,8.15574878 L20.3468301,8.64992157 L18.045385,12.9934581 C17.981539,13.1134681 17.9855281,13.2586812 18.0543643,13.3756862 C18.123198,13.4926704 18.2488955,13.5642753 18.382584,13.5642753 L20.3458494,13.5642753 L20.3458494,16.5152157 C20.3458494,17.8403764 21.4122824,18.9184637 22.723118,18.9184637 L26.093933,18.9184637 L26.093933,20.6127372 C26.093933,20.7156045 26.1338368,20.8144364 26.2066621,20.8870484 C26.2784889,20.9596602 26.3752565,21 26.4770118,21 L33.8024499,21 C34.0129426,20.9989913 34.1845301,20.805359 34.1845301,20.5915669 L34.1845301,13.8457119 C35.6669583,12.3158183 36.4800008,10.3009689 36.4800008,8.15579266 C36.4819937,3.65890023 32.8627385,0 28.4144579,0 C23.9661338,0 20.3468301,3.65885634 20.3468301,8.15574878 Z M30.8873572,10.761906 C30.7706636,10.761906 30.649874,10.7554748 30.5260159,10.7436852 C30.3560919,11.254916 30.3929441,11.8679649 30.606882,12.3159537 C30.606882,12.3159537 30.0940494,12.7157085 29.4829216,12.5667429 C29.4829216,12.5667429 28.4736081,11.8165098 28.5985014,10.202455 C27.8625065,9.85628435 27.3435157,9.43079375 26.9054198,9.06854507 C26.4765218,8.71485669 26.1376906,8.43513804 25.7292588,8.32689969 C25.4446983,8.25187637 25.1242892,8.19614665 24.8141263,8.14255701 C23.8815937,7.98179355 22.9183487,7.81567442 22.4536285,7.06865142 C22.1741779,6.61852252 22.1260655,6.04297981 22.3072497,5.30989472 C22.8692169,3.03779463 25.5655848,1.68 27.8462096,1.68 C28.1359064,1.68 28.4174009,1.70250644 28.6835393,1.7464493 C32.2395636,2.3359025 34.1805711,5.19745578 33.9400089,7.51147937 C33.8581181,8.27348289 33.4025958,10.7621969 30.8876455,10.7621969 L30.8873572,10.761906 Z"
                  id="education"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default KownledgeIcon;
