import React from "react";

const ChatIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 95 95"
    >
      <path
        d="m37.98 65.57c-3.0625 0-6.0156-0.34766-8.832-0.94922l-12.84 12.84v-18.184c-6.918-4.6602-11.309-11.492-11.309-19.129 0-14.039 14.766-25.422 32.98-25.422 18.215 0 32.98 11.383 32.98 25.422 0 14.039-14.766 25.422-32.98 25.422zm31.25-42.398c4.1211 4.8438 6.5391 10.688 6.5391 16.973 0 14.688-13.164 26.949-30.543 29.66 4.9219 2.2578 10.648 3.5781 16.793 3.5781 3.0625 0 6.0156-0.34766 8.832-0.94922l12.84 12.84v-18.18c6.918-4.6602 11.309-11.492 11.309-19.133 0-12.129-11.027-22.25-25.77-24.789z"
        transform="translate(0 -0.001)"
        fill={!color ? "#fff" : color}
      />
    </svg>
  );
};

export default ChatIcon;
