import React from "react";

const MedicalIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 26 25"
    >
      <path
        id="Path"
        d="M26,15.64V9.36a1.885,1.885,0,0,0-1.921-1.847H18.187V1.847A1.885,1.885,0,0,0,16.266,0H9.734A1.885,1.885,0,0,0,7.813,1.847V7.512H1.921A1.885,1.885,0,0,0,0,9.36V15.64a1.885,1.885,0,0,0,1.921,1.847H7.813v5.665A1.885,1.885,0,0,0,9.734,25h6.532a1.885,1.885,0,0,0,1.921-1.847V17.488h5.892A1.885,1.885,0,0,0,26,15.64Z"
        fill={!color ? "#fff" : color}
      />
    </svg>
  );
};

export default MedicalIcon;
