import React from "react";

const LeagueContactIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 45 29.001"
    >
      <g
        id="noun_ID_badge_715963"
        dataname="noun_ID badge_715963"
        transform="translate(0 0)"
      >
        <path
          id="Path"
          d="M.885,5H13.1a.963.963,0,0,0,.688-.275.71.71,0,0,0,.2-.633A6.051,6.051,0,0,0,11.334.168a1.01,1.01,0,0,0-1.115,0,5.8,5.8,0,0,1-6.434,0,1.01,1.01,0,0,0-1.115,0A6.051,6.051,0,0,0,.01,4.1a.711.711,0,0,0,.2.622A.96.96,0,0,0,.885,5Z"
          transform="translate(6 17)"
          fill={!color ? "#fff" : color}
        />
        <circle
          id="Oval"
          cx="4"
          cy="4"
          r="4"
          transform="translate(9 7)"
          fill={!color ? "#fff" : color}
        />
        <path
          id="Shape"
          d="M42.562,29H2.439A2.54,2.54,0,0,1,0,26.376V2.628A2.54,2.54,0,0,1,2.439,0H10.4c.332,0,2.16,0,5.146,0L25.32,0H42.562A2.539,2.539,0,0,1,45,2.628V26.376A2.539,2.539,0,0,1,42.562,29ZM4.249,3.4A1.524,1.524,0,0,0,2.785,4.973V24.029A1.524,1.524,0,0,0,4.249,25.6H19.792a1.524,1.524,0,0,0,1.463-1.575V4.973A1.524,1.524,0,0,0,19.792,3.4ZM26.24,19.748a1.579,1.579,0,0,0,0,3.149H30.6a1.579,1.579,0,0,0,0-3.149Zm0-6.824a1.579,1.579,0,0,0,0,3.149h8.75a1.579,1.579,0,0,0,0-3.149Zm0-6.821a1.523,1.523,0,0,0-1.463,1.574A1.523,1.523,0,0,0,26.24,9.25H40.285a1.523,1.523,0,0,0,1.463-1.573A1.523,1.523,0,0,0,40.285,6.1Z"
          fill={!color ? "#fff" : color}
        />
      </g>
    </svg>
  );
};

export default LeagueContactIcon;
