import React from "react";

import StatsContainer from "../../Dashboard/StatsContainer";
import ContractInfo from "./ContractInfo";
import ContractDetailsTable from "./ContractDetailsTable";
import ContractSpotracSalaryChart from "./ContractSpotracSalaryChart";
import { userTypes } from "../../../utils/constants";

const DetailsContainer = ({ selected, user, ...props }) => {
  const { parent, page } = props;
  return (
    <>
      <StatsContainer
        contract={true}
        selected={selected}
        user={user}
        parent={parent}
        page={page}
      />
      <ContractInfo selected={selected} user={user} />
      {((user?.userType === userTypes.PLAYER && user?.spotracId) ||
        selected) && [
        <ContractDetailsTable selected={selected} user={user} key={"1"} />,
        <ContractSpotracSalaryChart
          user={user}
          selected={selected}
          key={"2"}
        />,
      ]}
    </>
  );
};

export default DetailsContainer;
