import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";

import Hero from "../Components/Login/Hero";
import Form from "../Components/Login/Form";
import RecoverPassword from "../Components/Login/RecoverPassword";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100vh",
  },
}));

const Login = () => {
  useEffect(() => {
    var imgElements = document.querySelectorAll("img");
    imgElements.forEach(function (imgElement) {
      if (imgElement.src?.includes("https://dante-chatbot-pictures")) {
        // Remove the img element
        imgElement.remove();
      }
    });
  }, []);

  const classes = useStyles();
  const [view, setView] = useState(0);
  return (
    <Grid container alignContent="flex-start" className={classes.container}>
      <Hero />
      {view == 0 ? (
        <Form setView={setView} />
      ) : (
        <RecoverPassword setView={setView} />
      )}
    </Grid>
  );
};

export default Login;
