import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { login } from "../../api/auth";

import arrow from "../../images/arrow-right-white.svg";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Form = ({ setView }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const onLogin = async () => {
    try {
      setLoading(true);
      await dispatch(login({ email, password }));
      setLoading(false);
      history.push("/");
    } catch (error) {
      setLoading(false);
      if (
        error.message.includes("USER_NOT_FOUND") ||
        error.message.includes("PASSWORD_INCORRECT")
      )
        return alert("Invalid email or password");
      alert(error);
    }
  };

  return (
    <Grid item sm={12} md={6} className={classes.container}>
      <h1 className={classes.h1}>Login</h1>
      <form className={classes.form}>
        <div className={classes.inputContainer}>
          <h6 className={classes.h6}>EMAIL</h6>
          <div className={classes.input}>
            <input
              autoComplete="email"
              className={classes.username}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <h6 className={classes.h6}>PASSWORD</h6>
          <div className={classes.input}>
            <input
              autoComplete="current-password"
              type="password"
              className={classes.password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <button
          className={classes.button}
          onClick={(e) => {
            e.preventDefault();
            onLogin();
          }}
        >
          {loading ? "Loading..." : "Login"}
          <img src={arrow} className={classes.arrowImg} alt="" />
        </button>
      </form>
      <div className={classes.containerLinks}>
        <p className={classes.p} onClick={() => setView(1)}>
          Forgot your password?
        </p>
        <p
          className={classes.pPrivacy}
          onClick={() => (window.location.href = "/privacy-policy")}
        >
          Privacy Policy
        </p>
        <p className={classes.pPatent}>Patent Pending</p>
      </div>
    </Grid>
  );
};

export default Form;

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "34px",
    },
  },
  containerLinks: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px",
  },
  h1: {
    fontSize: "36px",
    ...setStyles.sofiaProBold,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  form: {
    textAlign: "center",
  },
  inputContainer: {
    textAlign: "left",
    margin: "38px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto 15px",
    },
  },
  h6: {
    padding: "0px 0px 10px 25px",
    fontSize: "12px",
  },
  input: {
    width: "380px",
    height: "61px",
    boxShadow: "0px 4px 10px #05070B33",
    borderRadius: "30px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "302px",
    },
  },
  username: {
    width: "90%",
    height: "30px",
    margin: "auto",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProRegular,
  },
  password: {
    width: "90%",
    height: "30px",
    margin: "auto",
    border: "none",
    fontSize: "24px",
    letterSpacing: "1.58px",
    ...setStyles.sofiaProRegular,
  },
  button: {
    width: "106px",
    height: "35px",
    backgroundColor: "#FF002B",
    borderRadius: "18px",
    border: "none",
    ...setStyles.sofiaProBold,
    fontSize: "17px",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
  arrowImg: {
    paddingLeft: "5px",
    width: "20px",
  },
  p: {
    fontSize: "16px",
    opacity: 0.5,
    cursor: "pointer",
  },
  pPrivacy: {
    fontSize: "12px",
    opacity: 0.5,
    cursor: "pointer",
  },
  pPatent: {
    fontSize: "10px",
    opacity: 0.5,
    marginTop: "10px",
  },
}));
