import React, { useState } from "react";
import { Box, Grid, Modal } from "@material-ui/core";
import topLeftImg from "../../images/card-status-top-left.png";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { HelpOutline } from "@material-ui/icons";
import { numberFormatter } from "../../utils/utils";
import { userTypes } from "../../utils/constants";
import { useReduxQuery } from "../../hooks/useReduxQuery";
import { fetchClients } from "../../api/user";

const StatsD = ({ user }) => {
  const classes = useStyles();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const agentQuery = useReduxQuery("Agent", fetchClients);

  const agent =
    user?.userType === userTypes.AGENT ? agentQuery?.agent?.clients ?? {} : {};

  const leaguesSet = new Set();
  Object?.values(agent)?.forEach((agentInfo) => {
    if (agentInfo?.team && agentInfo?.team?.league !== null) {
      leaguesSet.add(agentInfo?.team?.league?.toUpperCase());
    }
  });

  const leaguesList = Array.from(leaguesSet);

  return (
    <>
      <Grid item sm={12} md={6} className={classes.statsGridItem}>
        <div className={classes.statsContainer}>
          <div className={classes.innerContainer}>
            <div className={classes.topLeftImg} />
            <div className={classes.topRightImg} />
            <div className={classes.innerBox1}>
              <div className={classes.innerBox3}>
                <p className={classes.p}>{`${
                  user?.userType !== userTypes.REPORTER
                    ? user?.userType
                    : "Industry"
                } Status`}</p>
              </div>
              <h3 className={classes.h3}>
                {user?.executive?.scoutType ||
                  user?.coach?.coachType ||
                  (user?.userType === userTypes.REPORTER
                    ? "Reporter"
                    : user?.status) ||
                  "N/A"}
              </h3>
              <p className={classes.p}>
                {(user?.executive && user.executive.yearsActive + " years") ||
                  (user?.coach && user.coach.yearsActive + " years") ||
                  (user?.reporter && user.reporter.yearsActive + " years") ||
                  (user?.agent && user.agent.yearsActive + " years") ||
                  user?.type ||
                  "N/A"}
              </p>
            </div>
            <div className={classes.innerBox2}>
              {user?.userType !== userTypes.REPORTER &&
                user?.userType !== userTypes.AGENT && [
                  <div className={classes.innerBox3} key={"1"}>
                    <p className={classes.p}>Next Paycheck</p>
                  </div>,
                  <h3 className={classes.h3} key={"2"}>
                    {user?.executive?.nextPaycheck
                      ? `$${numberFormatter(user?.executive?.nextPaycheck)}`
                      : user?.coach?.nextPaycheck
                      ? `$${numberFormatter(user?.coach?.nextPaycheck)}`
                      : "N/A"}
                  </h3>,
                  <p className={classes.p} key={"3"}>
                    {"(ESTIMATED)"}
                  </p>,
                ]}
              {user?.userType === userTypes.REPORTER && [
                <div className={classes.innerBox3} key={"1"}>
                  <p className={classes.p}>Team Reporter</p>
                </div>,
                <h3 className={classes.h3} key={"2"}>
                  {(user?.team?.city &&
                    user?.team.name &&
                    user?.team?.city + " " + user?.team?.name) ||
                    "NA"}
                </h3>,
              ]}
              {user?.userType === userTypes.AGENT && [
                <div className={classes.innerBox3} key={"1"}>
                  <p className={classes.p}>Active Clients</p>
                </div>,
                <h3 className={classes.h3} key={"2"}>
                  {Object.keys(agent)?.length || "NA"}
                </h3>,
                <p className={classes.p} key={"3"}>
                  {`${leaguesList.join(", ")}`}
                </p>,
              ]}
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default StatsD;

const useStyles = makeStyles((theme) => ({
  statsGridItem: {
    width: "100%",
    maxWidth: "480px",
    [theme.breakpoints.down("sm")]: {
      margin: "auto auto 20px",
    },
  },
  statsContainer: {
    width: "480px",
    // height: "150px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "75px auto auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  contractStatsContainer: {
    margin: "auto",

    [theme.breakpoints.down("sm")]: {
      margin: "75px auto auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  innerContainer: {
    height: "150px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px grey",
    display: "flex",
    position: "relative",
  },
  topLeftImg: {
    position: "absolute",
    backgroundImage: `url(${topLeftImg})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "125px",
    height: "125px",
  },
  topRightImg: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundImage: `url(${topLeftImg})`,
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "125px",
    height: "125px",
    transform: "scaleX(-1)",
  },
  innerBox1: {
    width: "50%",
    borderRight: "1px solid lightgrey",
    margin: "25px auto",
    textAlign: "center",
    zIndex: 2,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  innerBox2: {
    width: "50%",
    margin: "25px auto",
    textAlign: "center",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  innerBox3: {
    textAlign: "center",
    zIndex: 2,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  h3: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "25px",
  },
  p: {
    ...setStyles.sofiaProRegular,
    color: "#a28c62",
    fontSize: "12px",
  },
  icon: {
    ...setStyles.sofiaProRegular,
    padding: "0px 10px",
    color: "#a28c62",
    fontSize: "12px",
  },
  p1: {
    ...setStyles.sofiaProBold,
    fontSize: "18px",
  },
  p2: {
    ...setStyles.sofiaProBold,
    fontSize: "16px",
    color: "#9b9c9d",
  },
  p3: {
    ...setStyles.sofiaProBold,
    fontSize: "24px",
  },
  p4: {
    ...setStyles.sofiaProBold,
    fontSize: "12px",
    color: "#9b9c9d",
  },
  a: {
    height: "44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    ...setStyles.sofiaProBold,
    textDecoration: "none",
    fontSize: "14px",
  },
}));
