import React from "react";

const ContractSpotracSalaryChart = ({ user, selected }) => {
  if (user?.spotracId === null || user?.spotracId === 0) {
    return null;
  }

  return (
    <iframe
      src={`https://www.spotrac.com/widget/player/${
        selected ? selected?.spotracId : user?.spotracId
      }/`}
      width="610px"
      height="610px"
      frameBorder={0}
    ></iframe>
  );
};

export default ContractSpotracSalaryChart;
