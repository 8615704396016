import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useReduxQuery } from "../hooks/useReduxQuery";
import { fetchNotifications, updateNotification } from "../api/notifications";
import { wait } from "../utils/utils";
import { useDispatch } from "react-redux";
import NotificationCard from "../Components/Notifications/NotificationCard";

const Notifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { notifications } = useReduxQuery("Notifications", fetchNotifications);

  const isNotifications = notifications && Object.keys(notifications).length;

  const unreadNotificationIds = React.useMemo(
    () =>
      Object.values(notifications || [])
        .filter((n) => !n.read)
        .map((n) => n.id),
    [notifications]
  );

  const readNotification = async (ids) => {
    await wait(5000);
    ids.forEach((id) => dispatch(updateNotification(id, { read: true })));
  };

  React.useEffect(() => {
    if (unreadNotificationIds?.length > 0)
      readNotification(unreadNotificationIds);
  }, [unreadNotificationIds]);

  return (
    <div className={classes.container}>
      {isNotifications ? (
        Object.values(notifications || [])
          .reverse()
          .map((n, i) => (
            <NotificationCard
              key={i}
              title={n?.notification?.title}
              description={n?.notification?.description}
              isRead={n?.read}
              objectId={n?.notification?.objectId}
              type={n?.notification?.type}
              createdAt={n?.createdAt}
              data={n?.notification?.data}
            />
          ))
      ) : (
        <div className={classes.text}>No Notifications</div>
      )}
    </div>
  );
};

export default Notifications;

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: "150px",
  },
  h4: {
    padding: "25px",
  },
  text: {
    padding: "25px",
    fontSize: "20px",
  },
  button: {
    width: 200,
    height: 35,
    borderRadius: 11,
    border: "none",
    backgroundColor: "#ff9100",
    color: "white",
    fontSize: 16,
  },
}));
