import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";

const useStyles = makeStyles((theme) => ({
  calContainer: {
    width: "47px",
    height: "53px",
    marginTop: "5px",
    borderRadius: "4px",
    overflow: "hidden",
  },
  calBox1: {
    height: "18px",
    backgroundColor: "#c71a21",
    fontSize: "11px",
    ...setStyles.sofiaProBold,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  calBox2: {
    height: "35px",
    backgroundImage: "linear-gradient(#faf8f3, #f3eee2)",
    color: "#05070b",
    fontSize: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...setStyles.sofiaProBold,
  },
}));

const Cal = ({ month, day }) => {
  const classes = useStyles();
  return (
    <div className={classes.calContainer}>
      <div className={classes.calBox1}>
        <p>{month}</p>
      </div>
      <div className={classes.calBox2}>
        <p>{day.toString()}</p>
      </div>
    </div>
  );
};

export default Cal;
