import React from "react";

const NotificationsIcon = ({ active }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>notifications</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Dashboards-v1"
        stroke="none"
        strokeWidth="1"
        fill="#FFFFFF"
        fillRule="evenodd"
      >
        <g
          id="Dashboard---Nav-Tray"
          transform="translate(-583.000000, -25.000000)"
        >
          <g id="Header" transform="translate(-104.000000, -23.000000)">
            <g id="Primary-Nav" transform="translate(356.000000, 47.000000)">
              <g id="Notifications" transform="translate(307.000000, 1.000000)">
                <g
                  id="notifications"
                  transform="translate(24.000000, 0.000000)"
                >
                  <path
                    d="M18.0000635,11.6611545 C18.0000635,11.3810272 17.9041882,11.1403887 17.7117455,10.939239 C17.5108482,10.7390206 17.2705328,10.6384409 16.9907704,10.6384409 C16.7025726,10.6384409 16.4622476,10.7390206 16.2697953,10.939239 C16.068898,11.1403984 15.969389,11.3810272 15.969389,11.6611545 L15.969389,17.5690703 C15.969389,17.8256872 15.8370228,17.954477 15.5722999,17.954477 L2.43901729,17.954477 C2.17427523,17.954477 2.0428655,17.8256969 2.0428655,17.5690703 L2.0428655,4.40638501 C2.05037565,4.16574651 2.18274188,4.04542725 2.43901729,4.04542725 L8.41157512,4.04542725 C8.6913375,4.04542725 8.93166254,3.94484758 9.13255024,3.74462913 C9.32406045,3.54441068 9.42074802,3.3037794 9.42074802,3.02271363 C9.42074802,2.74164786 9.32405565,2.50100936 9.13255024,2.30079813 C8.93165293,2.10057967 8.6913375,2 8.41157512,2 L2.43901729,2 C0.804615013,2 -0.00845265487,2.80180748 6.34777287e-05,4.40638501 L6.34777287e-05,17.5690703 C6.34777287e-05,19.1896263 0.813170584,20 2.43901729,20 L15.5722999,20 C17.1907449,20 18.0000635,19.1897225 18.0000635,17.5690703 L18.0000635,11.6611545 Z M20,3.99503723 C20,2.88689901 19.6124724,1.94469983 18.8373952,1.16659101 C18.0468881,0.388570234 17.102937,0 16.005564,0 C14.8935512,0 13.9495782,0.388570234 13.1746108,1.16659101 C12.5452954,1.7975995 12.1723418,2.54208035 12.0548719,3.39994553 C12.0188628,3.59853098 12,3.79626252 12,3.99483916 C12,5.10209707 12.3918296,6.04860979 13.1746108,6.83340899 C13.949666,7.61142977 14.893639,8 16.005564,8 C16.1590343,8 16.3202217,7.99656018 16.4882615,7.98882442 L16.4994071,7.98882442 C17.3850842,7.88652323 18.1644194,7.50139502 18.8373908,6.83341339 C19.6124461,6.04852616 20,5.10201344 20,3.99484357 L20,3.99503723 Z"
                    id="Fill-1"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NotificationsIcon;
