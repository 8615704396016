import { appendParamsToUrl, axiosHandler } from "../utils/utils";
import { SPOTTRAC_KEY, SPOTTRAC_API_URL } from "../utils/constants.js";

export const fetchPlayerData = async (spotracId) => {
  try {
    if (!spotracId) return;
    const params = { key: SPOTTRAC_KEY };
    const json = await axiosHandler({
      url: `player/${spotracId}?` + appendParamsToUrl(params),
      customBaseURL: SPOTTRAC_API_URL,
    });
    return json.player;
  } catch (error) {
    throw error;
  }
};
