import React from "react";

import Contacts from "./Contacts";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import Arrow from "../../images/Icons/Arrow";

const Details = ({ content, contacts, event }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.detailsCont}>
          <h3 className={classes.h3}>Details</h3>
          <p className={classes.p}>{content}</p>
        </div>

        <div className={classes.contactCont}>
          {contacts && contacts?.length > 0 && (
            <h2 className={classes.h2}>Important Event Contact</h2>
          )}
          {contacts?.map((c, i) => (
            <Contacts key={i} contact={c} />
          ))}
        </div>
      </div>
      {(event?.externalLinks || [])?.length > 0 && (
        <div className={classes.linksContainer}>
          {(event?.externalLinks || []).map((l, i) => (
            <button className={classes.CBAConnectContainer}>
              <a
                href={l}
                className={classes.CBAConnect}
                target="_blank"
                rel="noreferrer"
              >
                {`More info`}
                <div className={classes.arrow}>
                  <Arrow color="blue" />
                </div>
              </a>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Details;

const useStyles = makeStyles((theme) => ({
  linksContainer: {
    marginTop: "40px",
  },
  container: {
    width: "100%",
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      width: "100%",
    },
  },
  detailsCont: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "25px",
    },
  },
  h3: {
    fontSize: "30px",
    ...setStyles.sofiaProBold,
    paddingBottom: "10px",
  },
  p: {
    fontSize: "16px",
    ...setStyles.sofiaProRegular,
  },
  CBAConnectContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    backgroundColor: "#D8E2F9",
    borderRadius: "46px",
    border: "none",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    marginBottom: "75px",
    cursor: "pointer",
    textDecoration: "none",
  },
  CBAConnect: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    maxWidth: "100%",
    height: "50px",
    color: "#3F6EDE",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    textDecoration: "none",
  },
  arrow: {
    marginTop: "3px",
    paddingLeft: "5px",
  },
}));
