import React from "react";

import moreIcon from "../../../images/more.svg";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../../setStyles";

const useStyles = makeStyles((theme) => ({
  iconLinkContainer: {
    cursor: "pointer",
    textAlign: "center",
  },
  iconLinkP: {
    fontSize: "0.75rem",
    ...setStyles.sofiaProBold,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  img: {
    width: "20px",
    height: "20px",
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
    },
  },
}));

const MoreButton = ({ moreHandler }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconLinkContainer} onClick={moreHandler}>
      <div>
        <img src={moreIcon} alt="" className={classes.img} />
        <p className={classes.iconLinkP}>Menu</p>
      </div>
    </div>
  );
};

export default MoreButton;
