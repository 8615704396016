import React from "react";

import EventCard from "../Standalones/EventCard";
import FeatureCard from "../Standalones/FeatureCard";
import FeatureCardSmall from "../Standalones/FeatureCardSmall";

import Arrow from "../../images/Icons/Arrow";

import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { fetchEvents } from "../../api/events";
import { useReduxQuery } from "../../hooks/useReduxQuery";
import EventsCards from "../Events/EventsCards";
import { dateFormat } from "../../utils/utils";

const EventsContainer = () => {
  const classes = useStyles();
  const { events } = useReduxQuery("Events", fetchEvents);

  return (
    <div className={classes.eventContainer}>
      <div className={classes.innerContainerHeader}>
        <h3 className={classes.h3}>Upcoming Events</h3>
        <a href="/events" className={classes.a}>
          <span className={classes.span}>View all calendar events</span>{" "}
          <Arrow color="grey" className={classes.arrow} />
        </a>
      </div>
      <EventsCards
        events={Object.values(events || [])
          .filter((e) => dateFormat(e?.endDate).unix() > dateFormat().unix())
          .sort(
            (a, b) =>
              dateFormat(a?.startDate).unix() - dateFormat(b?.startDate).unix()
          )}
      />
    </div>
  );
};

export default withWidth()(EventsContainer);

const useStyles = makeStyles((theme) => ({
  eventContainer: {
    width: "100%",
    height: "100%",
    maxWidth: setStyles.maxWidth,
    margin: "100px auto auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  innerContainerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  h3: {
    fontSize: "30px",
    ...setStyles.sofiaProSemiBold,
    color: "#05070b",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
  a: {
    ...setStyles.sofiaProSemiBold,
    color: "#05070b",
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
  },
  span: {
    opacity: "0.6",
  },
  arrow: {
    [theme.breakpoints.down("xs")]: {
      width: "12px",
    },
  },
  feature1: {
    gridColumnEnd: "span 2",
    gridRowEnd: "span 2",
    height: "100%",
    width: "100%",
  },
  feature2: {
    gridColumnEnd: "span 1",
    gridRowEnd: "span 2",
    height: "100%",
    width: "100%",
  },
}));
