// Actions
const NOTIFICATION_TOKEN = "settings/NOTIFICATION_TOKEN";
const LOAD_ALL = "settings/LOAD_ALL";
const REMOVE_ALL = "settings/REMOVE_ALL";

// Reducer
const initialState = {
  notificationToken: null,
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NOTIFICATION_TOKEN:
      return { ...state, notificationToken: action.token };

    case LOAD_ALL:
      return {
        ...state,
        data: action.settings.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setNotificationToken(token) {
  return { type: NOTIFICATION_TOKEN, token };
}
export function loadSettings(settings) {
  return { type: LOAD_ALL, settings };
}
export function removeSettings() {
  return { type: REMOVE_ALL };
}
