// Actions
const LOAD_ALL = "articles/LOAD_ALL";
const LOAD_UNIQUE = "articles/LOAD_UNIQUE";
const REMOVE_ALL = "articles/REMOVE_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.articles.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.article.id]: action.article,
        },
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const loadArticles = (articles) => ({ type: LOAD_ALL, articles });
export const loadArticle = (article) => ({ type: LOAD_UNIQUE, article });
export const removeArticles = () => ({ type: REMOVE_ALL });
