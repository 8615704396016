import React from "react";
import { Grid } from "@material-ui/core";
import Carousel from "../Standalones/Carousel";

import ArticleCard from "../Standalones/ArticleCard";

import Arrow from "../../images/Icons/Arrow";
import bgImage from "../../images/bg-wave-light-gold.svg";

import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { truncate } from "../../utils/utils";

const ArticlesContainer = ({ width, articles, title, button }) => {
  const classes = useStyles();

  const displayCards = () => {
    const comps = Object.values(articles || [])?.map((article, i) => (
      <div className={classes.articleDiv} key={i}>
        <ArticleCard
          subject={`${(article.tags || [])
            .map((t) =>
              t
                .replace(/contractrepository/gi, "Contract Repository")
                .replace(/strategicpartners/gi, "Strategic Partners")
                .replace(
                  /professionaldevelopment/gi,
                  "Professional Development"
                )
                .toUpperCase()
            )

            .join(", ")}`}
          heading={article.title}
          content={truncate(article.description)}
          id={article.id}
        />
      </div>
    ));

    if (isWidthUp("md", width)) {
      return (
        <Grid
          container
          justifyContent="flex-start"
          className={classes.cardsContainer}
        >
          {comps}
        </Grid>
      );
    } else {
      return <Carousel>{comps}</Carousel>;
    }
  };

  return (
    <div className={classes.CBACont}>
      <div className={classes.innerContainer}>
        <div className={classes.innerContainerHeader}>
          {title !== undefined && <h3 className={classes.h3}>{title}</h3>}
          {button !== undefined && (
            <a href="/playerEducation" className={classes.a}>
              <span className={classes.span}>View all player questions</span>
              <Arrow color="grey" className={classes.arrow} />
            </a>
          )}
        </div>
        {displayCards()}
      </div>
    </div>
  );
};

export default withWidth()(ArticlesContainer);

const useStyles = makeStyles((theme) => ({
  CBACont: {
    width: "100%",
    margin: "auto",
    // minHeight: "575px",
    display: "flex",
    alignItems: "center",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  innerContainer: {
    width: "100%",
    maxWidth: setStyles.maxWidth,
    margin: "auto",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  innerContainerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  h3: {
    fontSize: "30px",
    ...setStyles.sofiaProSemiBold,
    color: "#05070b",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
  a: {
    ...setStyles.sofiaProSemiBold,
    color: "#05070b",
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
  },
  span: {
    opacity: "0.6",
  },
  arrow: {
    [theme.breakpoints.down("xs")]: {
      width: "12px",
    },
  },
  cardsContainer: {
    marginTop: "20px",
    width: "100%",
  },
  articleDiv: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
    minWidth: "300px",
    marginTop: "20px",
    marginRight: "10px",
    marginLeft: "10px",
  },
}));
