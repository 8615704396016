import React from "react";

import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { defaultServices, doctorRoles, lawyerRoles } from "../../lib/constants";

const Filter = ({
  filters,
  filter,
  onSelect,
  nbContacts,
  search,
  onSearch,
}) => {
  const classes = useStyles();

  const rolesByService = {
    Doctor: doctorRoles,
    Lawyer: lawyerRoles,
  };

  const handleRoleSelect = (e, f) => {
    const role = e.target.value;
    const filter = f;

    onSelect({ filter, role });
  };

  filters.sort((a, b) => {
    if (Object.values(defaultServices).includes(a)) return 1;
    if (Object.values(defaultServices).includes(b)) return -1;
    return 0;
  });

  return (
    <div className={classes.container}>
      <p className={classes.p}>{`${nbContacts} Contact${
        nbContacts > 1 ? "s" : ""
      } Found`}</p>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.sortContainer}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.innerSortCont1}
        >
          <Grid item xs={12} sm={12} md={"auto"}>
            <h5>Filter By: </h5>
          </Grid>
          <div>
            {filters.map((f, i) =>
              !Object.values(defaultServices || []).includes(f) ? (
                <button
                  key={i}
                  name={0}
                  className={
                    filter === f ? classes.sortButtonA : classes.sortButton
                  }
                  onClick={() => onSelect(f)}
                >
                  {f?.toUpperCase()}
                </button>
              ) : (
                <select
                  key={i}
                  name={0}
                  className={
                    filter === f ? classes.sortButtonA : classes.sortButton
                  }
                  style={{
                    maxWidth: "175px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={(e) => handleRoleSelect(e, f)}
                >
                  <option value={"All"}>{f.toUpperCase()}</option>
                  {Object.values(rolesByService[f] || []).map((r, i) => (
                    <option key={i} value={r}>
                      {r.toUpperCase() + " " + f.toUpperCase()}
                    </option>
                  ))}
                </select>
              )
            )}
          </div>
        </Grid>
        <Grid container alignItems="center" className={classes.innerSortCont2}>
          <input
            placeholder={`Search...`}
            value={search}
            className={classes.searchInput}
            onChange={(e) => onSearch(e?.target?.value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Filter;

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "auto",
    padding: "45px 0px 15px",
    borderBottom: "2px solid rgba(155, 156, 157, .2)",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  p: {
    color: "#05070B",
    fontSize: "24px",
    ...setStyles.sofiaProRegular,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  sortContainer: {
    padding: "21px 0px 15px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "12px 0px",
    },
  },
  h5: {
    ...setStyles.sofiaProBold,
    fontSize: "18px",
  },
  innerSortCont1: {
    width: 300,
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "auto",
    },
  },
  innerSortCont2: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto auto",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "15px auto auto",
    },
  },
  searchInput: {
    height: "35px",
    width: "300px",
    borderRadius: "20px",
    boxShadow: "0px 0px 10px #0000003E",
    border: "none",
    padding: "5px 15px",
    ...setStyles.sofiaProBold,
    fontSize: "18px",
    color: "#05070B",
  },
  sortButtonA: {
    marginRight: "10px",
    marginBottom: "10px",
    padding: "2px 10px",
    border: "none",
    backgroundColor: "#A28C62",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  sortButton: {
    marginRight: "10px",
    marginBottom: "10px",
    padding: "2px 10px",
    border: "none",
    backgroundColor: "rgb(233,203,147, 0.2)",
    borderRadius: "4px",
    color: "#A28C62",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  alphaContainer: {
    backgroundColor: "rgb(233,203,147, 0.2)",
    borderRadius: "8px",
    height: "56px",
    padding: "0px 20px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  letterButton: {
    color: "#A28C62",
    fontSize: "18px",
    ...setStyles.sofiaProBold,
    background: "none",
    padding: "14px 11px",
    border: "none",
  },
}));
